import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../services/login.service';
import { CommonService } from '../../../../../../common/services/common.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomDialogComponent } from 'apps/common/components/custom-dialog/custom-dialog.component'
import { isBlank } from 'apps/common/helper/util-function-class';

@Component({
  selector: 'login-bulletin-documents',
  templateUrl: './bulletin-documents.component.html',
  styleUrls: ['./bulletin-documents.component.css']
})

export class BulletinDocumentsComponent implements OnInit {
  bulletinDocList: any = [];
  hostname: string;
  fileCatgoryCount: number;

  constructor(private service: LoginService, public commonService: CommonService, public spinnerService: NgxSpinnerService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.spinnerService.show();
    this.service.getPortalBulletins(this.setPortalBulletinsCallback, this.showErrorMsg);
  }

  setPortalBulletinsCallback = (result: any) => {
    this.spinnerService.hide();
    if (result && result.length > 0) {
      this.fileCatgoryCount = result.length;
      this.bulletinDocList = result;
    } else {
      var infoArea = document.getElementById('no-videos-list');
      infoArea.textContent = 'No Records found.';
    }
  }

  openFile(row) {
    if(!isBlank(row.documentS3Filename)) {      
      let url = window.location.protocol+"//"+window.location.host+"/ONPublicWeb/"+row.documentS3Filename;      
      window.open(window.location.protocol+"//"+window.location.host+"/ONPublicWeb/"+row.documentS3Filename, '_blank');
    } else {      
      let yesNoDialogConfig: MatDialogConfig = new MatDialogConfig();
      yesNoDialogConfig.ariaLabel = " ";
      yesNoDialogConfig.autoFocus = false;
      yesNoDialogConfig.data = { type: 'confirm-dialog', title: $localize`Document is currently unavailable. Please check again later.`, ok: $localize`Ok`};
      const aDialogReference = this.dialog.open(CustomDialogComponent, yesNoDialogConfig);
      aDialogReference.afterClosed().subscribe(dialogData => {
        if (dialogData != null) {
          return;
        }
        return;
      });
    }
    return false;
  }

  cancel() {
    this.commonService.goToHomePage();
  }

  showErrorMsg = (error: any) => {
    this.spinnerService.hide();
    if (error.status === 400) {
      alert($localize`Invalid request parameters!`);
    } else if (error.status === 503) {
      alert($localize`Service is not available. Please try again later!`);
    } else if (error.error != null && error.error.msg !== undefined && error.error.msg != null && error.error.msg.length > 0) {
      console.error(error.error.msg);
      alert($localize`Oops, something went wrong! Please try again later`);
    } else {
      console.error(error.message + '(response code: ' + error.status + ')');
      alert($localize`Oops, something went wrong! Please try again later`);
    }
  }

}
