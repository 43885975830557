<ngx-spinner bdColor="rgba(170,170,170,0.4)" size="medium" color="black" type="ball-spin-fade-rotating"
    [fullScreen]="false">
    <p i18n style="color: white">Loading...</p>
</ngx-spinner>

<div style="width: 90%; margin-left: 38px;">
    <button i18n id="ICAA.VIC.Home" type="button" mat-raised-button (click)="cancel()">Back to previous</button><br><br>
    <span i18n style="font-size: 18px; font-family: Arial, Helvetica, sans-serif; font-weight: 700;">    
        DriveON Industry Communications
    </span><br/><br/>
    <span i18n style="font-size: small; font-family: Arial, Helvetica, sans-serif;">
      Click on a bulletin issue to view
    </span><br/><br/>
    <mat-expansion-panel *ngFor="let step of bulletinDocList index as i" [expanded]="i == 0">
        <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
            <mat-panel-title id="ICAA.InspectionCentre.SOP" class="mpt">
                    <span style="font-size: 16px; font-weight: bold;">{{step.yearDesc}}</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <span *ngFor="let step1 of step.bulletinDocumentDtoList">           
            <a i18n style="font-size: medium; font-family: Arial, Helvetica, sans-serif; vertical-align:middle" href="#" (click)="openFile(step1)" tabindex="0">
                <img style="height:32px;width:32px;vertical-align:bottom; padding-right: 5px;" src="assets/img/normal_u4.png" alt="step.dispBulletDocFilename">{{step1.dispBulletDocFilename}}
            </a><br><br>
        </span>
    </mat-expansion-panel>
</div>
