import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { CommonService } from 'apps/common/services/common.service';
import { getLocale } from 'apps/common/helper/util-function-class';
import createReportCardPdf from '../../../template/pdfReportCardService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'apps/common/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake/build/vfs_fonts.js');
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'login-report-card',
  templateUrl: './report-card.component.html',
  styleUrls: ['./report-card.component.css']
})
export class ReportCardComponent implements OnInit {
  @ViewChild('pARReportTable', { read: MatSort, static: false }) pARReportTableSort: MatSort;
  @ViewChild('paginatorReport', { read: MatPaginator }) paginatorReport: MatPaginator;
  @ViewChild('fileUpload', { static: false }) fileUploadRef: ElementRef;
  version: string = environment.version;
  stations: any = [];
  env: string = environment.env;
  bulletImage = 'assets/img/arrow_right.png';
  pARReportDataSource = new MatTableDataSource([]);
  defaultPARReportColumns = ['reportName','date','download','action' ];

  pARReports: any = [];
  stationReportCards: any = {};
  manualPARReports: any = {};
  isPRAParsonOrDelegate:boolean=false;
  PRA_PARSONS_ROLE_NAME = "praparsons";
  message: string = "";
  msgServiceError = $localize`Oops, something went wrong! Please try again later.`;
  lblUserId = $localize`User ID`;
  lblEmail = $localize`Email Address`;
  lblCancel = $localize`Cancel`;
  lblSubmit = $localize`Submit`;
  uploadMessage: string = null
  supportedUploadTypes = ["image/png", "image/jpeg",
    "application/pdf", "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
  loadingDocs = false;
  files = [];
  uploaDocs: any = [];
  selectedDoc = null;
  reportQuarters: any = [];
  searchCriteria: any = {};
  loggedInPersonId: any;
  reportCardDataSource = new MatTableDataSource([]);
  reportCardColumns = ['stationId', 'stationName', 'address', 'inspectionCategoryCd', 'view', 'startDate', 'quarter', 'overallPerformanceGradeId'];
  docDefinition:any;
  action:string;
  expandReport:boolean = false;
  currentUserRoles: any = [];
  currentLocale: string;
  inspectionbCentreList: any=[];

  constructor(
    public service: LoginService,
    public commonService: CommonService,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.spinnerService.show();
    this.currentLocale = getLocale();
    this.expandReport = true;
    this.loggedInPersonId = this.commonService.getPersonId().toString();
    this.route.params.subscribe(params => {
      let personId = params['personId'];
      if(this.loggedInPersonId !== personId){
        alert($localize`Not Authorized`);
        window.location.href = '/on-login-web/' + getLocale() +"/#/login/sso";
      }
      console.log(personId);
      this.service.getInspectionCentreList(personId, personId, this.setInspectionCentreList, this.commonService.showErrorMsg);
    });
    this.searchCriteria.inspectionCategoryCd = 'EMS';
    this.searchCriteria.locale = getLocale();
    let userAuthorities = this.commonService.getUserAuthorities();
    this.isPRAParsonOrDelegate= userAuthorities.filter(item => item.role.toLowerCase().includes(this.PRA_PARSONS_ROLE_NAME)).length > 0;
    this.service.getReportQuarters(this.getReportQuartersCallback, this.showErrorMsg);
  }
  getReportQuartersCallback = (result: any) => {
    this.reportQuarters = result;
    result.forEach((obje, index) => {
      if(index == 0) {
        this.searchCriteria.reportPeriod = obje.code;
        this.searchCriteria.reportPeriodValue = obje.value;
        this.route.params.subscribe(params => {
          let stationId = params['stationId'];
          this.onStationSelected(stationId);
          if (stationId != null && stationId.length > 0) {
            this.expandReport = true;
            this.search();
          }
        });
        return;
      }
    });
  }

  addSubStationList(stationList){
    this.stations=[];
    this.inspectionbCentreList = stationList;
    for (var i = 0 ; i < this.inspectionbCentreList.length ; i++) {
      if(this.inspectionbCentreList[i].stationId !== '' && this.inspectionbCentreList[i].stationId !== undefined)
        this.stations.push({code:this.inspectionbCentreList[i].stationId, value: (this.inspectionbCentreList[i].stationName + '-' + this.inspectionbCentreList[i].stationId)});
    }
    if(this.stations.length>0)
      this.searchCriteria.stationId=this.stations[0].code;

    this.spinnerService.hide();
    return;
  }

  setInspectionCentreList = (result: any)  => {
    if(result) {
      this.addSubStationList(result);
    }
  }

  onChangeStationId(stationId: any) {
    if (isNaN(stationId)) {
      this.searchCriteria.stationId = undefined;
    }
    else {
      this.searchCriteria.stationId = stationId.target.value;
    }
  }

  onStationSelected(value: any) {
    this.searchCriteria.stationId = value;
  }

  search() {
    this.message = "";
    if(this.searchCriteria.stationId != undefined && this.searchCriteria.reportPeriod != undefined) {
      this.spinnerService.show();
      const dateArray = this.searchCriteria.reportPeriod.split(",");
      this.searchCriteria.startDate = dateArray[0];
      this.searchCriteria.endDate = dateArray[1];
      this.service.getStationReportCards(this.searchCriteria, this.getStationReportCardsCallback, this.showErrorMsg);
    }
    else {
      this.message = $localize`Please enter VIC ID`;
    }
  }

  getStationReportCardsCallback = (result: any) => {
    this.spinnerService.hide();
    if (result != null && result.length > 0) {
      this.stationReportCards = result;
      this.reportCardDataSource = new MatTableDataSource(this.stationReportCards);
    }
    else {
      this.message = $localize`No data found`;
      this.reportCardDataSource = new MatTableDataSource([]);
    }
  }

  view(row) {
    this.action = 'view';
    this.service.getStationReportCard(row.inspectionCategoryCd, row.stationId, row.startDate, row.endDate, this.getStationReportCardCallback, this.showErrorMsg);
    return false;
  }

  print(row) {
    this.action = 'print';
    this.service.getStationReportCard(row.inspectionCategoryCd, row.stationId, row.startDate, row.endDate, this.getStationReportCardCallback, this.showErrorMsg);
  }

  download(row) {
    this.action = 'download';
    this.service.getStationReportCard(row.inspectionCategoryCd, row.stationId, row.startDate, row.endDate, this.getStationReportCardCallback, this.showErrorMsg);
    return false;
  }

  getStationReportCardCallback= (result: any) => {
    if(result != null && result.stationList != null && result.stationList.length > 0) {
      for(const aStationDto of result.stationList) {
        aStationDto.aLocale = this.searchCriteria.locale;
        aStationDto.inspectionCategoryCd = result.inspectionCategoryCd;
        this.docDefinition = createReportCardPdf(aStationDto, result.pdList, result.pgList, result.tvList);
        switch(this.action) {
          case 'view': {
            pdfMake.createPdf(this.docDefinition).open();
            break;
          }
          case 'print': {
            pdfMake.createPdf(this.docDefinition).print();
            break;
          }
          case 'download': {
            pdfMake.createPdf(this.docDefinition).download(aStationDto.reportFileName);
            break;
          }
          default: {
          }
        }
        break;
      }
    }
    else if (result != null) {
      console.error('getStationReport error:\n ' + JSON.stringify(result));
    }
    else {
      console.error('getStationReport failed.');
    }
  }

  showErrorMsg = (error: any) => {
    this.spinnerService.hide();
    if (error.error.message != null && error.error.message.length > 0) {
      this.message = error.error.message;
    }
    else {
      this.message = error.message + '(response code: ' + error.status + ')';
    }
  }

  downloadReport(reportId,fileName) {
    this.service.downloadReport(reportId).subscribe(
      response => {
        this.spinnerService.hide();
        let blob: any = new Blob([response.body], { type: response.body.type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
      }, err => {
        this.message = 'Could not download the file!' + err;
      });
  }
}

function closeWindow() : void {
    window.self.close();
}
