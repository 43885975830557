<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="ball-spin-fade-rotating"
  [fullScreen]="true">
  <p i18n style="color: white">Loading...</p>
</ngx-spinner>
<main class="main container-fluid">
  <div style="width: 75%; margin-left: 38px;">
    <span i18n style="font-size: 18px;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 700;">
      Reports 
    </span><br/>
    <span i18n style="font-size: small; font-family: Arial, Helvetica, sans-serif;">
      Click on each accordion below to see the reports.
    </span>
  </div>
  <br/>
  <div style="width: 98%; margin-left: 30px;">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let item of pARReports; first as isFirst" [expanded]="isFirst && !expandReport && !expandVICReport" (opened)="populateManualReports(item)">
        <mat-expansion-panel-header>
          <mat-panel-title aria-label="{{item.key}}">
            {{item.key}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent><br/>
          <mat-list-item *ngFor="let report of item.quickSightReports">
            <img  [src]="bulletImage" style="border: 0px; vertical-align: middle;width: 1.3em;" alt="report">
            <p style="margin-top: -19px; padding-left: 32px;"> 
              <a (click)="showQuickSightReport(report)" tabindex="0" target="_blank"
                (keyup.enter)="showQuickSightReport(report)">{{currentLocale === 'en'?report.reportName:report.reportNameFr}}</a>
            </p>
          </mat-list-item><br/>
          <div id="LOGIN.Reports.upload">
            <fieldset class="form-group" style="border: solid; border-width: thin; border-color: #d3cdcd; padding: 10px;" *ngIf="item.manualReports != undefined">
              <legend i18n style="width:auto; border:0px; margin: 0px; font-size: 14px; font-weight: bold;">Manually Generated Report(s)</legend>
              <app-padded-panel>
                <mat-table #pARReportTable [dataSource]="pARReportDataSource" matSort class="mat-elevation-z8">
                  <!-- FileName Column -->
                  <ng-container matColumnDef="reportName">
                    <mat-header-cell i18n *matHeaderCellDef mat-sort-header>File Name</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      <span i18n class="mobile-label">File Name</span>
                      <span style="display: flex">{{currentLocale === 'en'?row.reportName:row.reportNameFr}}</span>
                    </mat-cell>
                  </ng-container>
                  <!-- Date Column -->
                  <ng-container matColumnDef="date">
                    <mat-header-cell i18n *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      <span i18n class="mobile-label">Date</span>
                      <span style="display: flex">{{row.date|date:'yyyy-MM-dd'}}</span>
                    </mat-cell>
                  </ng-container>
                  <!-- Download Column -->
                  <ng-container matColumnDef="download">
                    <mat-header-cell i18n *matHeaderCellDef>Download</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      <span i18n class="mobile-label">Download</span>
                      <input type="image" i18n src="assets/img/download.png" style="cursor: pointer;" 
                        [style.display]="(row.reportUrl != undefined && row.reportUrl != null && row.reportUrl != '') ? 'inline':'none'" 
                        alt="Download" width="20" height="20" (click)="downloadReport(row.reportId,row.reportUrl)"/>
                    </mat-cell>
                  </ng-container>
                  <!-- Action Column -->
                  <ng-container matColumnDef="action">
                    <mat-header-cell i18n *matHeaderCellDef>Action</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      <span i18n class="mobile-label">Action</span>
                      <button i18n id="PRA.Reports.Upload" tabindex="0" *ngIf="isPRAParsonOrDelegate"
                        mat-raised-button (click)="onUploadFiles(item.key,row.reportId)">Upload 
                      </button>&nbsp;&nbsp;
                      <input type="file" accept="image/jpeg, image/png, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document" 
                        #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" 
                        style="display:none;"/>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="defaultPARReportColumns"></mat-header-row>
                  <mat-row [ngClass]="{'selectedRow': row.isSelected}" tabindex="0"
                    *matRowDef="let row; columns: defaultPARReportColumns;"
                    (keyup.enter)="toggleRowSelected(row, $event, pARReportDataSource)"
                    (click)="toggleRowSelected(row, $event, pARReportDataSource)">
                  </mat-row>
                </mat-table>
                <br/>
              </app-padded-panel>
            </fieldset>
          </div>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="currentUserRoles.includes('PRAReportCardViewer')" [expanded]="expandReport || expandVICReport">
        <mat-expansion-panel-header>
          <mat-panel-title aria-label="VIC REPORT CARDS">
            VIC REPORT CARDS
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent><br/>
          <div *ngIf="!expandVICReport">
            <label i18n class="important_instruction" style="font-size: 16px; line-height: 1.2em; font-weight: bold;">
              Please enter VIC ID/Name, then click the "Search" button. 
            </label>
          </div>
          <br>
          <div fxLayout="row wrap" fxLayoutGap="10px" fxLayout.lt-md="column">
            <component-autocomplete-field 
              i18n-placeholder placeholder="Vehicle Inspection Centre ID/Name"  *ngIf="!expandVICReport"
              appwidth="100%" fxFlex="45%" [appStyle]="{'width':'100%'}" [searchValue]="searchCriteria.stationId" 
              [searchApiURL]="searchApiURL" [minSearchLength]="3" (keyup)="onChangeStationId($any($event).target.value)"
              (optionSelected)="onStationSelected($event.value)">
            </component-autocomplete-field>&nbsp;&nbsp;&nbsp;&nbsp;
              <app-input-field i18n-placeholder *ngIf="expandVICReport" placeholder="Station"  [inputValue]="stationName" [inputdisabled]="true" appwidth="100%"></app-input-field>
            <mat-form-field  fxFlex="45%">
              <select matNativeControl i18n-placeholder placeholder="Report Period" [(ngModel)]="searchCriteria.reportPeriod" aria-label="Report Period" required>
                <option style="height: 1.7em;" *ngFor="let o of reportQuarters; index as i" 
                  value="{{o.code}}">{{o.value}}
                </option>
              </select>
            </mat-form-field>        
          </div>
          <button i18n mat-raised-button (click)="search()">Search</button>&nbsp;&nbsp;&nbsp;&nbsp;<span style="color:#6D00DA;">{{message}}</span>
          <br/><br>
          <fieldset style="border: solid; border-width: thin; border-color: #d3cdcd; padding: 10px;">
            <legend i18n style="width:auto; border:0px; margin: 0px; font-size: 14px; font-weight: bold;">Search Results</legend>
            <label i18n >
              Click View or Download to see the Report Card 
            </label>
              <mat-table #reportCardTable [dataSource]="reportCardDataSource" matSort class="mat-elevation-z8">
                <ng-container matColumnDef="stationId">
                  <mat-header-cell i18n *matHeaderCellDef mat-sort-header>VIC #</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span i18n class="mobile-label">VIC #</span>
                    <span style="display: flex">{{row.stationId}}</span>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="stationName">
                  <mat-header-cell i18n *matHeaderCellDef mat-sort-header>VIC Name</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span i18n class="mobile-label">VIC Name</span>
                    <span style="display: flex">{{row.stationName}}</span>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="address">
                  <mat-header-cell i18n *matHeaderCellDef mat-sort-header>Address</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span i18n class="mobile-label">Address</span>
                    <span style="display: flex">{{row.address}}</span>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="inspectionCategoryCd">
                  <mat-header-cell i18n *matHeaderCellDef mat-sort-header>Category</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span i18n class="mobile-label">Category</span>
                    <span style="display: flex">{{row.inspectionCategoryCd}}</span>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="view">
                  <mat-header-cell i18n *matHeaderCellDef mat-sort-header>PDF</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span class="mobile-label"></span>
                    <div *ngIf="!isIOS">
                      <a i18n href="#" (click)="view(row);" tabindex="0">View</a>&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    <div>
                      <a i18n href="#" (click)="download(row);" tabindex="0">Download</a>
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="startDate">
                  <mat-header-cell i18n *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span i18n class="mobile-label">Date</span>
                    <span style="display: flex">{{row.startDate}}</span>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="quarter">
                  <mat-header-cell i18n *matHeaderCellDef mat-sort-header>Quarter</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span i18n class="mobile-label">Quarter</span>
                    <span style="display: flex">{{row.quarter}}</span>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="overallPerformanceGradeId">
                  <mat-header-cell i18n *matHeaderCellDef mat-sort-header>Overall Grade</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span i18n class="mobile-label">Overall Grade</span>
                    <span style="display: flex">{{row.overallPerformanceGradeId == 1 || row.overallPerformanceGradeId == 5 ?"A":
                                                  row.overallPerformanceGradeId == 2 || row.overallPerformanceGradeId == 6 ?"B":
                                                  row.overallPerformanceGradeId == 3 || row.overallPerformanceGradeId == 7 ?"C":"D" }}</span>
                  </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="reportCardColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: reportCardColumns;"></mat-row>
              </mat-table>
          </fieldset>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</main>