import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, AbstractControl} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {switchMap, map, catchError, debounceTime} from 'rxjs/operators';
import {CommonService } from '../../services/common.service';
import { isBlank} from 'apps/common/helper/util-function-class';

@Component({
  selector: 'component-autocomplete-field',
  templateUrl: './autocomplete-field.component.html',
  styleUrls: ['./autocomplete-field.component.css']
})
export class AutocompleteFieldComponent implements OnInit {

  @Input() placeholder:string = null;
  @Input() searchValue = null;
  @Input() searchApiURL = null;
  @Input() idOptions: any[] =[];
  @Input() inputdisabled = false;
  @Input() required = false;
  @Input() cmpntId = null;
  @Input() appStyle = {'width':'400px'};
  @Input() minSearchLength = 3;
  @Input() appwidth = null;
  @Output() optionSelected = new EventEmitter();

  apiCallResult: any = {};
  autoCompletFormControl: UntypedFormControl;

  filteredIdOptions: Observable<any[]>;
  toHighlight = '';

  constructor(private fb: UntypedFormBuilder, public commonService: CommonService) {
  }

  ngOnInit() {
    this.autoCompletFormControl = new UntypedFormControl();
    this.filteredIdOptions = this.getFilteredIdOptions(this.searchApiURL, this.autoCompletFormControl);
  }



  reset = () => {
    this.cmpntId="";
    this.searchValue = "";
    this.filteredIdOptions = this.getFilteredIdOptions(this.searchApiURL, this.autoCompletFormControl);
  }

  getFilteredIdOptions(searchApiUrl:string, formInput:AbstractControl): Observable<string[]> {
    const _this = this;
    if (searchApiUrl != null ) {
      return formInput.valueChanges.pipe(
        debounceTime(300),
        switchMap(value => _this.refreshIdOptions(searchApiUrl, value))
        );
    } else {
        return formInput.valueChanges.pipe(
        map(value => _this.filter(value))
        );
    }
  };

  refreshIdOptions(searchApiUrl:string, value: string): Observable<any> {
    if (!isBlank(searchApiUrl) && !isBlank(value)) {
      let id = value.toLowerCase().trim();
      const ids = id.split(' ');
      if (id.length >= this.minSearchLength) {
        return this.commonService.search(searchApiUrl, {"id":id}).pipe(
          map(
            result => {
              this.apiCallResult = result;
              if (this.apiCallResult.idOptions != null) {
                this.idOptions = this.apiCallResult.idOptions != null? this.apiCallResult.idOptions:[];
                this.toHighlight = id;
                return this.idOptions.filter(option => option.id != null && option.value != null?option.id.toLowerCase().includes(ids[0].toLowerCase()) || (option.value.toLowerCase().includes(ids[0].toLowerCase()) && (ids.length <= 1 || option.value.toLowerCase().includes(ids[1].toLowerCase()))):'');
              }
            }
          ),
          catchError( _ => null)
        );
      }
      else {
        return of([]);
        // this.toHighlight = id;
        //return  of(this.idOptions.filter(option => option.id != null && option.value != null?option.id.toLowerCase().includes(ids[0].toLowerCase()) || (option.value.toLowerCase().includes(ids[0].toLowerCase()) && (ids.length <= 1 || option.value.toLowerCase().includes(ids[1].toLowerCase()))):''));
      }
    }
    return of([]);
  }

  onFieldBlurClient(searchValue:any){
      if(searchValue == '' || searchValue === undefined){
        this.optionSelected.emit('');
      }
  }

  filter(value:string) {
    this.toHighlight = value;
    return this.idOptions.filter(option => option.id != null && option.value != null?option.id.toLowerCase().includes(value.toLowerCase()) ||option.value.toLowerCase().includes(value.toLowerCase()):'');
  }

  onOptionSelected(event) {
    this.optionSelected.emit(event.option);
  }

  clear(){
    this.searchValue='';
    this.optionSelected.emit('');
  }

  getOptValue = (id: any) => {
    if (id) {
      const option = this.idOptions.filter(option => option.id != null?option.id.toLowerCase() === id.toLowerCase() :'');
      return option && option.length === 1? option[0].value:'';
    }
    return null;
  }
}