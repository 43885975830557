import { Injectable} from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders, HttpParams, HttpEvent} from '@angular/common/http';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, Subject} from 'rxjs';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import jwt_decode from 'jwt-decode';
import { SimpleDialogComponent } from 'apps/common/components/simple-dialog/simple-dialog.component';
import { CustomDialogComponent } from 'apps/common/components/custom-dialog/custom-dialog.component';
import { environment } from '../environments/environment';
import { getLocale, convertRoles, isEmpty} from 'apps/common/helper/util-function-class';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject } from 'rxjs';

const AUTH_HEADER_KEY = 'Authorization';
const AUTH_PREFIX_BEARER = 'Bearer';
const datePipe:DatePipe = new DatePipe('en-US');
const httpOptionsJson = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };
const uploadhttpOptions = { headers: new HttpHeaders({}) };

@Injectable({
  providedIn: 'root'
})

export class CommonService {
  public keycloakFrench = false;
  private token:any = {};
  private callingKeycloakLogin = false;

  private umaApiUrl = environment.umaApiUrl;
  private lookupApiUrl = environment.lookupApiUrl;
  private loginApiUrl = environment.loginApiUrl;
  private emisApiUrl = environment.emisApiUrl;
  private iccaApiUrl = environment.icaaApiUrl;
  private icpaaApiUrl = environment.icpaaApiUrl;

  public userProfile: any = {organizationId:''};

  public userId:string = null;
  public personId: number;
  public userName:string = null;
  public userStatus:string = null;
  public stationId:string = null;
  public userAuthorities:any = null;
  public pwdResetRequired:boolean = false;

  public menuItems: any[] = [];
  public topMenuItems:any[] = [];

  public submenu={UMA:[], ICAA:[], AMT:[], TMQ:[], ICPAA:[], VCA:[], FCRA:[], EPBA:[], EPA:[], PRA:[], SMA:[], STMA:[]};
  private cmpntControls:any = {};
  private pageControls:any = {};

  idleSessionTimeout = environment.idleSessionTimeout;
  sessionTimeout:any = null;
  idleStatus:any = "NEW";

  dialogConfig:MatDialogConfig = new MatDialogConfig();
  skipLoadPageControls = false;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  public searchCache: Map<string, any> = new Map();

  constructor(public http:HttpClient, public _cookie: CookieService, private router: Router, private dialog: MatDialog, private idle: Idle, private keycloakService: KeycloakService) {
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.setIdle(environment.idleTime);
    this.idleStatus = "NEW";
    this.dialogConfig.ariaLabel=" ";
    this.dialogConfig.autoFocus= false;

    this.idle.onIdleStart.subscribe(() =>
    {
      this.idleStatus = "IDLE";
      const confirmDialogConfig = this.dialogConfig;
      const dialogService = this.dialog;
        confirmDialogConfig.data = {  type:'confirm-dialog-countdown', title:'Your session is about to timeout in <CounterDown> minutes. Do you want to continue?',
        counterDownInitValue:this.idleSessionTimeout/60, counterDownInterval:1, counterDownIntervalUnit:'min',
        ok:'Yes',cancel:'No'
      };
      const aDialogReference = dialogService.open(CustomDialogComponent, confirmDialogConfig);
      aDialogReference.afterClosed().subscribe(dialogData => {
        if( dialogData !== null && dialogData !== undefined ) {
          this.idleStatus = "NEW";
          this.refreshAccessToken();
        } else {
          this.logout();
        }
      });
    });
  }

  public editDataDetails: any[] = [];
  public subject = new Subject<any>();

  public useKeycloak() {
    if (!isEmpty(environment.keycloakPingUrl))
      return true;
    else
      return false;
  }

  public getMatchingLocalTime(inputDate: Date){
    if (inputDate != undefined && inputDate != null) {
      const d = new Date(inputDate);
      // This will return an ISO string matching your local time.
      return new Date(d.getFullYear(), d.getMonth(), d.getDate());
    }
    else {
      return null;
    }
  }

  public postApi(apiUrl:string, request:any, callback: (result:any) => void, errorCallback:(error:any) => void, ...args: any[]) {
    const httpOptions = args.length < 2?httpOptionsJson:args[1] =='multipart'?{ headers: new HttpHeaders({}) }:{ headers: new HttpHeaders({ 'Content-Type': args[1]}) };
    if (this.userId != null) {
      const accessToken = this.getAuthToken();
      if (accessToken != null) {
        const accessTokenHeaders = httpOptions.headers.append(AUTH_HEADER_KEY, `${AUTH_PREFIX_BEARER} ${accessToken}`);
        const options = args.length < 1? {headers: accessTokenHeaders}:{headers: accessTokenHeaders, responseType:args[0] as 'json'};

        this.http.post(apiUrl, request, options).subscribe(
          {
            next: (data) => {
              callback(data);
            },
            error: (error) => {
              errorCallback(error);
            }
          }
        );
      } else {
        errorCallback({error:{msg:'Token timeout!'}});
      }
    } else {
      const options = args.length < 1?{}:{responseType:args[0] as 'json'};
      this.http.post(apiUrl, request, options).subscribe(
        {
          next: (data) => {
            callback(data);
          },
          error: (error) => {
            errorCallback(error);
          }
        }
      );
    }
  }

  public getApi(apiUrl:string, callback: (result) => void, errorCallback:(error) => void, ...args: any[]) {
    this.getApiWithParam(apiUrl, null, callback, errorCallback, ...args);
  }

  public getApiWithParam(apiUrl:string, params:any, callback: (result:any) => void, errorCallback:(error:any) => void, ...args: any[]) {
    if (!apiUrl.startsWith(this.lookupApiUrl) && this.userId != null) {
      const accessToken = this.getAuthToken();
      if (accessToken != null) {
        let options = null;
        const accessTokenHeaders = httpOptionsJson.headers.append(AUTH_HEADER_KEY, `${AUTH_PREFIX_BEARER} ${accessToken}`);
        
        let httpParams = null;
        if (params) {
          httpParams = new HttpParams();
          Object.keys(params).forEach(key => {
            httpParams = httpParams.set(key, params[key]);
          });
        }

        if (args.length < 1) {
          options = httpParams?{headers: accessTokenHeaders, params:httpParams}:{headers: accessTokenHeaders};
        } else {
          options = httpParams?{headers: accessTokenHeaders, responseType:args[0] as 'json', params:httpParams}:{headers: accessTokenHeaders, responseType:args[0] as 'json'};
        }

        this.http.get(apiUrl, options).subscribe(
          {
            next: (data) => {
              callback(data);
            },
            error: (error) => {
              errorCallback(error);
            }
          }
        );
      } else {
        errorCallback({error:{msg:'Token timeout!'}});
      }
    } else {
      this.http.get(apiUrl).subscribe(
        {
          next: (data) => {
            callback(data);
          },
          error: (error) => {
            errorCallback(error);
          }
        }
      );
    }
  }

  public lookup(lookObj:string, callback) {
    this.getApi(this.lookupApiUrl + '/' + getLocale() + '/get/' + lookObj, callback, this.errorCallback);
  }

  public getLookupObjById(lookObj:string, id:string, callback) {
    this.getApi(this.lookupApiUrl + '/' + getLocale() + '/get/' + lookObj + '/' + id, callback, this.errorCallback);
  }

  public getStationTestTypes(locale:string, callback) {
    this.getApi(this.lookupApiUrl + '/getStationTestTypes/'+ locale, callback, this.errorCallback);
  }

  public vehicleMakeList(vehType:string,fuelType:string,callback) {
    this.getApi(this.emisApiUrl + '/getVehicleMakeDetailsByType/'+vehType+'?fuelType='+fuelType, callback, this.errorCallback);
  }

  public vehicleModelList(vehMake:string,vehType:string,fuelType:string,callback) {
    this.getApi(this.emisApiUrl + '/getVehicleModelDetailsByMakeType/'+ vehMake+'/'+vehType+'?fuelType='+fuelType, callback, this.errorCallback);
  }

  public getStationById(stationId:string, callback) {
    this.getApi(this.iccaApiUrl + '/getVicInfo/'+stationId, callback, this.errorCallback);  
  }

  public findTechnicianById(technicianId:string, callback) {
    this.getApi(this.icpaaApiUrl + '/findTechnicianById/'+technicianId, callback, this.errorCallback);  
  }

  public mtoApprove(userId:string, callback) {
    this.postApi(this.iccaApiUrl + '/mtoApproveFromDashboard/'+userId, null, callback, this.errorCallback);
  }

  public directorApprove(userId:string,stationId:string, callback) {
    this.postApi(this.iccaApiUrl + '/directorApproveFromDashboard/'+userId+stationId != undefined?"/"+stationId:"", null, callback, this.errorCallback);
  }

  public search(apiUrl, searchBy) {
    const accessToken = this.getAuthToken();
    if (accessToken != null) {
      const accessTokenHeaders = httpOptionsJson.headers.append(AUTH_HEADER_KEY, `${AUTH_PREFIX_BEARER} ${accessToken}`);
      return this.http.get (apiUrl + '?searchBy=' + encodeURI(JSON.stringify(searchBy)), {headers: accessTokenHeaders });
    } 
    else {
      return null;
    }
  }

  checkEmailExists(email: string, callback, errorCallback) {
    this.getApi(environment.loginApiUrl + '/validateNewEmail?email=' + email, callback, errorCallback);
  }

  isMobilePhoneExist(phone: string, callback, errorCallback) {
    this.getApi(environment.loginApiUrl + '/isMobilePhoneExist?phone=' + phone, callback, errorCallback);
  }

  getOwnerCompany(personId: number, callback, errorCallback) {
    this.getApi(environment.icaaApiUrl + '/getCompanyInfo/' + personId + "/0", callback, errorCallback);  
  }

  getUserProfile(userId: string, callback, errorCallback) {
    this.getApi(environment.umaApiUrl + '/getUserProfile/' + userId, callback, errorCallback);    
  }

  setAuthToken(token:any) {
    if (token) {
      this.setCookie('Authorization', token);
      this.getAuthToken();
    }
  }

  getAuthToken():any {
    const authToken = this._cookie.get('Authorization');
    if (authToken) {
      this.token = jwt_decode(authToken );
      let authData = this.token.authData;
      if (authData) {
        authData = JSON.parse(this.token.authData);
        this.userId = authData.userId;
        this.personId = authData.personId;
        const expTime = new Date(this.token.exp * 1000);
        if (this.userId != null && this.userId.length > 0 && expTime > new Date()) {
          this.userName = authData.userName;
          this.userStatus = authData.userStatus;
          this.stationId = authData.stationId;
          this.userAuthorities = authData.userAuthorities;

          if (this.getCookie('pwdExpiryDays') === null) this.setCookie('pwdExpiryDays', authData.pwdExpiryDays);
          if (this.getCookie('secQAResetRequired') === null) this.setCookie('secQAResetRequired', authData.secQAResetRequired);

          return authToken;
        } 
        else {
          this.cleanUserData();
          return null;
        }
      }
      else {
        authData = this.token;
        if (authData.locale == "fr")
          this.keycloakFrench = true;
        this.userId = authData.preferred_username;
        this.personId = authData.personId;
        const expTime = new Date(this.token.exp * 1000);
        if (this.userId != null && this.userId.length > 0 && expTime > new Date()) {
          this.userName = authData.name;
          this.userStatus = authData.userStatus;
          this.userAuthorities = convertRoles(authData.userAuthorities);
          if (this.getCookie('pwdExpiryDays') == null) this.setCookie('pwdExpiryDays', authData.pwdExpiryDays);
          if (this.getCookie('secQAResetRequired') == null) this.setCookie('secQAResetRequired', authData.secQAResetRequired);
          return authToken;
        } 
        else {
          this.cleanUserData();
          return null;
        }
      }
    } 
    else {
      this.cleanUserData();
      return null;
    }
  }

  getCookie(key:string):any {
    const value = this._cookie.get(key);
    return value? value:null;
  }

  setCookie(key:string, value:any) {
    this._cookie.set(key, value , 1, '/', null, false, "Strict") ;
  }

  clearCookie(key:string) {
    this._cookie.set(key, null , -1, '/', null, false, "Strict") ;
  }

  cleanUserData() {
    this.userId = null;
    this.personId = null;
    this.userName = null;
    this.userStatus = null;
    this.stationId = null;
    this.userAuthorities = null;
  }

  getAuthTokenExpTime():any {
    const cookie = this._cookie.get('Authorization');
    if (cookie) {
      this.token = jwt_decode(cookie );
      return this.token.exp
    }
    return null;
  }

  clearAuthToken() {
    this._cookie.set('Authorization', '' , -1, '/', null, false, "Strict") ; ;
  }

  getUserId() {
    if (this.userId == null) {
      this.getAuthToken();
    }
    return this.userId;
  }

   getPersonId() {
    if (this.userId == null) {
      this.getAuthToken();
    }
    return this.personId;
  }

  getUserStatus() {
    if (this.userId == null) {
       this.getAuthToken();
    }
    return this.userStatus;
  }

  getUserName() {
    if (this.userId == null) {
       this.getAuthToken();
    }
    return this.userName;
  }

  getCurrentUserid() {
    return this.getUserId();
  }

  getUserDepartment(userId:string, callback, errorCallback){
    this.getApi(this.umaApiUrl + '/getUserDepartment/' + userId, callback, errorCallback);
  }

  isQuickSightAuthor() {
    for(const authority of this.userAuthorities) {
      if( authority.role.includes("Author") )
        return true;
    }
    return false;
  }

  getStationId() {
    if (this.userId == null) {
      this.getAuthToken();
    }
    return this.stationId;
  }

  getUserAuthorities() {
    if (this.userAuthorities == null) {
      this.getAuthToken();
    }
    return this.userAuthorities;
  }

  public downloadFile(apiUrl): Observable<any> {
    if (this.userId != null) {
      const accessToken = this.getAuthToken();
      if (accessToken != null) {
        const accessTokenHeaders = uploadhttpOptions.headers.append(AUTH_HEADER_KEY, `${AUTH_PREFIX_BEARER} ${accessToken}`);
        return this.http.get(apiUrl, { responseType: 'blob', observe: 'response', headers: accessTokenHeaders});
      }
    }
    else {
      return this.http.get(apiUrl, { responseType: 'blob', observe: 'response'});
    }
  }

  public downloadFilePost(apiUrl, requestParams: any): Observable<any> {
    if (this.userId != null) {
      const accessToken = this.getAuthToken();
      if (accessToken != null) {
        const accessTokenHeaders = uploadhttpOptions.headers.append(AUTH_HEADER_KEY, `${AUTH_PREFIX_BEARER} ${accessToken}`);
        return this.http.post(apiUrl, requestParams, { responseType: 'blob', observe: 'response', headers: accessTokenHeaders});
      }
    }
    else {
      return this.http.post(apiUrl, requestParams, { responseType: 'blob', observe: 'response'});
    }
  }

  public uploadFile(apiUrl, formData): Observable<HttpEvent<any>> {
    if (this.userId != null) {
      const accessToken = this.getAuthToken();
      if (accessToken != null) {
        const accessTokenHeaders = uploadhttpOptions.headers.append(AUTH_HEADER_KEY, `${AUTH_PREFIX_BEARER} ${accessToken}`);
        const req = new HttpRequest('POST', apiUrl, formData, {
          headers: accessTokenHeaders,
          reportProgress: true,
          responseType: 'json'
        });
        return this.http.request(req);
      }
    }
    else {
      const req = new HttpRequest('POST', apiUrl, formData, {
        reportProgress: true,
        responseType: 'json'
      });
      return this.http.request(req);
    }
  }

  isLoggedIn():boolean {
    return this.getUserId() != null && !this.pwdResetRequired ;
  }

  ssoLogin() {
    const loginOptions = { redirectUri:environment.keycloak.redirectHost+'/on-login-web/' + getLocale() + '/#/home/kc', locale:getLocale() };
    this.keycloakService.login(loginOptions);
  }

  isSSOLogin():boolean {
    return this.getCookie('isKeycloakLogin') == 'Y';
  }

  callingSSOLogin():boolean {
    return this.callingKeycloakLogin;
  }

  isKeycloakUp():boolean {
    const keycloakInstance = this.keycloakService.getKeycloakInstance();
    return keycloakInstance != null;
  }

  async getKeycloakToken(){
    if(this.isKeycloakUp()) {
      this.callingKeycloakLogin = true;
      if (await this.keycloakService.isLoggedIn()) {
        const token = await this.keycloakService.getToken();
        if (token) {
          this.callingKeycloakLogin = false;
          this.setCookie('isKeycloakLogin', 'Y')
          this.setAuthToken(token);
        }
      }
    }
  }

  public loadCmpntControlsCallback = (result:any) => {
    if (result !== null && result.length > 0) {
      for (const codeValue of result) {
        if( this.cmpntControls[codeValue.code] === undefined || this.cmpntControls[codeValue.code] === null
          || this.cmpntControls[codeValue.code] !== 'E' || this.cmpntControls[codeValue.code] === 'D' ) {
          this.cmpntControls[codeValue.code] = codeValue.value;
        }
      }
    }
  }

  public loadCmpntControls(pageCode: string) {
    this.getApi(this.umaApiUrl+'/getUmaCmpntControls/'+this.userId+'/'+pageCode,
                this.loadCmpntControlsCallback, this.errorCallback);
  }

  public isCmpntEnabled(cmpntName: string, defaultValue?:boolean):boolean {
    if( this.cmpntControls != null && this.cmpntControls[cmpntName] != null ) {
      return this.cmpntControls[cmpntName] === 'E';
    }
    return defaultValue !== undefined?defaultValue:false;
  }

  public isCmpntDisabled(cmpntName: string, defaultValue?:boolean):boolean {
    if( this.cmpntControls != null && this.cmpntControls[cmpntName] != null ) {
      return this.cmpntControls[cmpntName] === 'D';
    }
    return defaultValue !== undefined ?defaultValue:false;
  }

  public isCmpntHidden(cmpntName: string, defaultValue?:boolean):boolean {
    if( this.cmpntControls != null && this.cmpntControls[cmpntName] != null) {
      return this.cmpntControls[cmpntName] === 'H';
    }
    return defaultValue !== undefined?defaultValue:false;
  }

  public isPageEnabled(pageId: string, defaultValue?:boolean):boolean {
    if( this.pageControls !== null && this.pageControls[pageId] != null) {
      return this.pageControls[pageId] === 'E';
    }
    return defaultValue !== undefined?defaultValue:false;
  }

  NavToMainIfPageNotEnabled(pageId) {
    if(!this.isPageEnabled(pageId) ) {
      this.router.navigate(['/']);
    }
  }

  public isAppEnabled(appId: string):boolean {
    return this.submenu[appId] != null && this.submenu[appId].length > 0;
  }

  public loadPageControls() {
    this.getApi(this.umaApiUrl+'/getUmaPageControls/'+this.userId,
                this.loadPageControlsCallback, this.errorCallback);
  }

  public loadPageControlsCallback = (result:any) => {
    if( result !== null && result.length > 0 ) {
      for(const codeValue of result) {
        if( this.pageControls[codeValue.code] === undefined || this.pageControls[codeValue.code] === null
          || this.pageControls[codeValue.code] !== 'E' || this.pageControls[codeValue.code] === 'D' ) {
          this.pageControls[codeValue.code] = codeValue.value;
        }
      }
      this.setMenuItems();
    }
  }

  setMenuItems() {
    if (this.isPageEnabled('UMA.MyProfile')) {
      this.submenu['UMA'].push({
        name: $localize`Change password`,
        link: '/change-password',
        isExternal: false
      });
    }
    if (this.isPageEnabled('UMA.MyProfile')) {
      this.submenu['UMA'].push({
        name: $localize`My Profile`,
        link: '/my-profile',
        isExternal: false
      });
    }
    if (this.isPageEnabled('UMA.MyRoles')) {
      this.submenu['UMA'].push({
        name: $localize`My Roles`,
        link: '/my-roles',
        isExternal: false
      });
    }
    if (this.isPageEnabled('UMA.MaintainUsers')) {
      this.submenu['UMA'].push({
        name: $localize`Maintain Users`,
        link: '/maintain-users',
        isExternal: false
      });
    }
    if (this.isPageEnabled('UMA.MaintainRoles')) {
      this.submenu['UMA'].push({
        name: $localize`Maintain Roles`,
        link: '/maintain-roles',
        isExternal: false
      });
    }
    if (this.isPageEnabled('UMA.MaintainApprovers')) {
      this.submenu['UMA'].push({
        name: $localize`Maintain Approvers`,
        link: '/maintain-approvers',
        isExternal: false
      });
    }
    if (this.isPageEnabled('UMA.ManageNotifications')) {
      this.submenu['UMA'].push({
        name: $localize`Manage Notifications`,
        link: '/manage-notifications',
        isExternal: false
      });
    }
    if (this.isPageEnabled('UMA.CloneUser')) {
      this.submenu['UMA'].push({
        name: $localize`Clone User`,
        link: '/clone-user',
        isExternal: false
      });
    }
    if (this.submenu['UMA'].length > 0) {
      this.menuItems.push({
        name: $localize`User Management`,
        link: '/on-uma-web/' + getLocale() + '/#',
        isExternal: true,
        show: false,
        menuTrigger: 'uma',
        submenu : this.submenu['UMA'],
      });
    }
    if (this.isPageEnabled('VCA.InspectionTypeDetermination')) {
      this.submenu['VCA'].push({
        name: $localize`Inspection Type Determination`,
        link: '/inspectionTypeDetermination',
        isExternal: false
      });
    }
    if (this.isPageEnabled('VCA.SpecifyVehicleInformation')) {
      this.submenu['VCA'].push({
        name: $localize`Specify Vehicle Information`,
        link: '/specifyVehicleInformation',
        isExternal: false
      });
    }
    if (this.isPageEnabled('VCA.SnapOpacityCriteria')) {
      this.submenu['VCA'].push({
        name: $localize`Snap Opacity Criteria`,
        link: '/snapOpacityMainScreen',
        isExternal: false
      });
    }
    if (this.isPageEnabled('VCA.SnapOpacityVariables')) {
      this.submenu['VCA'].push({
        name: $localize`Snap Opacity Variables`,
        link: '/snapOpacityVariablesMainScreen',
        isExternal: false
      });
    }
    if (this.isPageEnabled('VCA.VehicleInspectionReport')) {
      this.submenu['VCA'].push({
        name: $localize`Vehicle Inspection Reports`,
        link: '/vehicleInspectionReport',
        isExternal: false
      });
    }
    if (this.isPageEnabled('VCA.ObdReadiness')) {
      this.submenu['VCA'].push({
        name: $localize`OBD Readiness`,
        link: '/obdReadinessMainScreen',
        isExternal: false
      });
    }
    if (this.isPageEnabled('VCA.ProgramVariables')) {
      this.submenu['VCA'].push({
        name: $localize`Program Variables`,
        link: '/programVariables',
        isExternal: false
      });
    }
    if (this.isPageEnabled('VCA.VehicleSignature')) {
      this.submenu['VCA'].push({
        name: $localize`Maintain Vehicle Signatures`,
        link: '/vehicleSignature',
        isExternal: false
      });
    }
    if (this.isPageEnabled('VCA.MaintainApprovers')) {
      this.submenu['VCA'].push({
        name: $localize`Maintain Approvers`,
        link: '/maintain-approvers'+getLocale()+'/#',
        isExternal: false
      });
    }
    if (this.submenu['VCA'].length > 0) {
      this.menuItems.push({
        name: $localize`Vehicle Control Application`,
        link: '/on-vca-web/' + getLocale() + '/#',
        isExternal: true,
        show: false,
        menuTrigger: 'vca',
        submenu : this.submenu['VCA'],
      });
    }

    if (this.isPageEnabled('PRA.Reports')) {
      this.submenu['PRA'].push({
        name: $localize`Reports`,
        link: '/reports',
        isExternal: false
      });
    }

    if (this.submenu['PRA'].length > 0) {
      this.menuItems.push({
        name: $localize`Program Reporting Application`,
        link: '/on-login-web/' + getLocale() + '/#',
        isExternal: true,
        show: false,
        menuTrigger: 'pra',
        submenu : this.submenu['PRA'],
      });
    }

    if (this.isPageEnabled('ICAA.InspectionCentre')) {
      this.submenu['ICAA'].push({
        name: $localize`VIC Administration`,
        link: '/inspectionCenterTabs',
        isExternal: false
      });
    }
    if (this.isPageEnabled('ICAA.ChangeManagement')) {
      this.submenu['ICAA'].push({
        name: $localize`VIC Change Management`,
        link: '/changeManagementSearch',
        isExternal: false
      });
    }
    if (this.isPageEnabled('ICAA.ChangeManagement')) {
      this.submenu['ICAA'].push({
        name: $localize`VIC Contract/Agreement`,
        link: '/vicReContracting',
        isExternal: false
      });
    }
    if (this.isPageEnabled('ICAA.ChangeSigningAuthority')) {
      this.submenu['ICAA'].push({
        name: $localize`Change of Signing Authority`,
        link: '/signingAuthorityChangeComponent',
        isExternal: false
      });
    }
    if (this.submenu['ICAA'].length > 0) {
      this.menuItems.push({
		  name: $localize`Inspection Centre Administration Application`,
        link: '/on-stationadmin-web/'+getLocale()+'/#',
        isExternal: true,
        show: false,
        menuTrigger: 'icaa',
        submenu : this.submenu['ICAA'],
      });
    }

    if (this.isPageEnabled('SMA.TabletMessaging')) {
      this.submenu['SMA'].push({
        name: $localize`Tablet Messaging`,
        link: '/tabletMessaging',
        isExternal: false
      });
    }

    if (this.isPageEnabled('SMA.EmailMessaging')) {
      this.submenu['SMA'].push({
        name: $localize`Email Messaging`,
        link: '/emailMessaging',
        isExternal: false
      });
    }

    if (this.submenu['SMA'].length > 0) {
      this.menuItems.push({
		  name: $localize`Messaging Application`,
        link: '/on-stationadmin-web/'+getLocale()+'/#',
        isExternal: true,
        show: false,
        menuTrigger: 'sma',
        submenu : this.submenu['SMA'],
      });
    }

    if (this.isPageEnabled('STMA.Overview')) {
      this.submenu['STMA'].push({
        name: $localize`Sticker Overview`,
        link: '/stickerOverview',
        isExternal: true
      });
    }
    if (this.isPageEnabled('STMA.FulfillOrder')) {
      this.submenu['STMA'].push({
        name: $localize`Fulfill Order`,
        link: '/fulfillOrders',
        isExternal: true
      });
    }
    if (this.isPageEnabled('STMA.OrderStickers')) {
      this.submenu['STMA'].push({
        name: $localize`Order Stickers`,
        link: '/orderStickers?tab=1',
        isExternal: true
      });
    }
    if (this.submenu['STMA'].length > 0) {
      this.menuItems.push({
		  name: $localize`Sticker Management Application`,
        link: '/on-sticker-web/'+getLocale()+'/#',
        isExternal: true,
        show: false,
        menuTrigger: 'stma',
        submenu : this.submenu['STMA'],
      });
    }

    if (this.isPageEnabled('AMT.AuditMaintenance')) {
      this.submenu['AMT'].push({
        name: `Audit Maintenance`,
        link: '/audit-maintenance',
        isExternal: true
      });
    }

    if (this.isPageEnabled('AMT.AuditEntry')) {
      this.submenu['AMT'].push({
        name: $localize`Audit Entry`,
        link: '/audit-entry',
        isExternal: true
      });
    }
    if (this.isPageEnabled('AMT.RealtimeAudits')) {
      this.idle.stop();
      console.log("For timeout: stop idle");
    }
    if (this.isPageEnabled('AMT.TriggerOccurrences')) {
      this.submenu['AMT'].push({
        name: $localize`Trigger Occurrences`,
        link: '/trigger-occurrences',
        isExternal: true
      });
    }
    if (this.isPageEnabled('AMT.Lockout')) {
      this.submenu['AMT'].push({
        name: $localize`Lockout`,
        link: '/lockout',
        isExternal: false
      });
    }
    if (this.isPageEnabled('AMT.Flags')) {
      this.submenu['AMT'].push({
        name: $localize`Flags`,
        link: '/flags',
        isExternal: true
      });
    }
    if (this.isPageEnabled('AMT.RestrictHours')) {
      this.submenu['AMT'].push({
        name: $localize`Restrict Hours`,
        link: '/restrictHours',
        isExternal: true
      });
    }
    if (this.isPageEnabled('AMT.Reports')) {
      this.submenu['AMT'].push({
        name: $localize`Reports`,
        link: '/reports',
        isExternal: true
      });
    }
    if (this.isPageEnabled('AMT.SafetyLevels')) {
      this.submenu['AMT'].push({
        name: $localize`Safety Levels`,
        link: '/safety-levels',
        isExternal: true
      });
    }
    if (this.isPageEnabled('AMT.StructuralLevels')) {
      this.submenu['AMT'].push({
        name: $localize`Structural Level Flags`,
        link: '/structural-levels',
        isExternal: true
      });
    }
    if (this.isPageEnabled('AMT.OOP')) {
      this.submenu['AMT'].push({
        name: $localize`Out of Province`,
        link: '/out-of-province',
        isExternal: true
      });
    }
    if (this.submenu['AMT'].length > 0) {
      this.menuItems.push({
        name: $localize`Audit Management and Trigger`,
        link: '/on-amt-web/'+getLocale()+'/#',
        isExternal: true,
        show: false,
        menuTrigger: 'amt',
        submenu : this.submenu['AMT'],
      });
    }
    if (this.isPageEnabled('TMQ.TrainingModules')) {
      this.submenu['TMQ'].push({
        name: $localize`Training Modules`,
        link: '/training-modules',
        isExternal: false
      });
    }
    if (this.isPageEnabled('TMQ.TrainingMaintenance')) {
      this.submenu['TMQ'].push({
        name: $localize`Training Maintenance`,
        link: '/training-maintenance',
        isExternal: false
      });
    }
    if (this.isPageEnabled('TMQ.TrainingStatus')) {
      this.submenu['TMQ'].push({
        name: $localize`Training Status`,
        link: '/training-status',
        isExternal: false
      });
    }
    if (this.isPageEnabled('TMQ.TrainingReports')) {
      this.submenu['TMQ'].push({
        name: $localize`Reports`,
        link: '/training-reports',
        isExternal: false
      });
    }
    if (this.submenu['TMQ'].length > 0) {
      this.menuItems.push({
        name: $localize`Training`,
        link: '/on-tmq-web/'+getLocale()+'/#',
        isExternal: true,
        show: false,
        menuTrigger: 'tmq',
        submenu : this.submenu['TMQ'],
      });
    }
    if (this.isPageEnabled('ICPAA.TechnicianQueryMinistry') ||
    this.isPageEnabled('ICPAA.TechnicianQueryParsons')) {
      this.submenu['ICPAA'].push({
        name: $localize`Manage Technicians`,
        link: '/technician-query',
        isExternal: false
      });
    }
    if (this.submenu['ICPAA'].length > 0) {
      this.menuItems.push({
        name: $localize`Personnel Administration`,
        link: '/on-icpaa-web/'+getLocale()+'/#',
        isExternal: true,
        show: false,
        menuTrigger: 'icpaa',
        submenu : this.submenu['ICPAA'],
      });
    }

    if (this.isPageEnabled('FCRA.PAD')) {
      this.submenu['FCRA'].push({
        name: $localize`PAD`,
        link: '/pad',
        isExternal: false
      });
    }
    if (this.isPageEnabled('FCRA.Discrepancy')) {
      this.submenu['FCRA'].push({
        name: $localize`PAD Discrepancies`,
        link: '/discrepancy',
        isExternal: false
      });
    }
    if (this.isPageEnabled('FCRA.StationLockout')) {
      this.submenu['FCRA'].push({
        name: $localize`Inspection Centre Lockout`,
        link: '/station-lockout',
        isExternal: false
      });
    }
    if (this.isPageEnabled('FCRA.Report')) {
      this.submenu['FCRA'].push({
        name: $localize`Report`,
        link: '/report',
        isExternal: false
      });
    }
    if (this.submenu['FCRA'].length > 0) {
      this.menuItems.push({
        name: $localize`Inspection Fees Collection & Reconciliation`,
        link: '/on-fcra-web/' + getLocale() + '/#',
        isExternal: true,
        show: false,
        menuTrigger: 'fcra',
        submenu : this.submenu['FCRA'],
      });
    }
    if (this.isPageEnabled('EPBA.PAD')) {
      this.submenu['EPBA'].push({
        name: $localize`PAD`,
        link: '/pad',
        isExternal: false
      });
    }
    if (this.isPageEnabled('EPBA.Discrepancy')) {
      this.submenu['EPBA'].push({
        name: $localize`PAD Discrepancies`,
        link: '/discrepancy',
        isExternal: false
      });
    }
    if (this.isPageEnabled('EPBA.StationLockout')) {
      this.submenu['EPBA'].push({
        name: $localize`Inspection Centre Lockout`,
        link: '/station-lockout',
        isExternal: false
      });
    }
    if (this.isPageEnabled('EPBA.Report')) {
      this.submenu['EPBA'].push({
        name: $localize`Report`,
        link: '/report',
        isExternal: false
      });
    }
    if (this.submenu['EPBA'].length > 0) {
      this.menuItems.push({
        name: $localize`Equipment Purchasing Billing`,
        link: '/on-epba-web/' + getLocale() + '/#',
        isExternal: true,
        show: false,
        menuTrigger: 'epba',
        submenu : this.submenu['EPBA'],
      });
    }

    if (this.isPageEnabled('EPA.EPAParsonsLandingPage')) {
      this.submenu['EPA'].push({
        name: $localize`Equipment View`,
        link: '/main-screen',
        isExternal: false
      });
    }
    if (this.isPageEnabled('EPA.EPAICLandingPage')) {
      this.submenu['EPA'].push({
        name: $localize`Equipment Order`,
        link: '',
        isExternal: false
      });
    }
    if (this.submenu['EPA'].length > 0 ) {
      this.menuItems.push({
        name: $localize`Equipment Purchase`,
        link: '/on-epa-web/'+getLocale()+'/#',
        isExternal: true,
        show: false,
        menuTrigger: 'epa',
        submenu : this.submenu['EPA'],
      });
    }

    const appUrl = window.location.href;
    this.topMenuItems = this.menuItems;
    if (appUrl.includes('on-uma-web')) {
      this.topMenuItems = this.menuItems.filter((item:any) => item.menuTrigger === 'uma')[0].submenu;
    } else if (appUrl.includes('on-stationadmin-web') && (!appUrl.includes('Messaging') && !appUrl.includes('messaging'))) {
      this.topMenuItems = this.menuItems.filter((item:any) => item.menuTrigger === 'icaa')[0].submenu;
    } else if (appUrl.includes('on-stationadmin-web') && (appUrl.includes('Messaging') || appUrl.includes('messaging'))) {
      this.topMenuItems = this.menuItems.filter((item:any) => item.menuTrigger === 'sma')[0].submenu;
    } else if (appUrl.includes('on-tmq-web')) {
      this.topMenuItems = this.menuItems.filter((item:any) => item.menuTrigger === 'tmq')[0].submenu;
    } else if (appUrl.includes('on-amt-web')) {
      this.topMenuItems = this.menuItems.filter((item:any) => item.menuTrigger === 'amt')[0].submenu;
    } else if (appUrl.includes('on-icpaa-web')) {
      this.topMenuItems = this.menuItems.filter((item:any) => item.menuTrigger === 'icpaa')[0].submenu;
    } else if (appUrl.includes('on-fcra-web')) {
      this.topMenuItems = this.menuItems.filter((item:any) => item.menuTrigger === 'fcra')[0].submenu;
    } else if (appUrl.includes('on-epba-web')) {
      this.topMenuItems = this.menuItems.filter((item:any) => item.menuTrigger === 'epba')[0].submenu;
    } else if (appUrl.includes('on-vca-web')) {
      this.topMenuItems = this.menuItems.filter((item:any) => item.menuTrigger === 'vca')[0].submenu;
    } else if (appUrl.includes('on-epa-web')) {
      this.topMenuItems = this.menuItems.filter((item:any) => item.menuTrigger === 'epa')[0].submenu;
    } else if (appUrl.includes('on-sticker-web')) {
      this.topMenuItems = this.menuItems.filter((item:any) => item.menuTrigger === 'stma')[0].submenu;
    }
  }

  setESAPageControls() {
    this.pageControls['ESA.PendingWorkOrder'] = 'E';
    this.pageControls['ESA.CompletedWorkOrder'] = 'E';
    this.pageControls['ESA.Inspection'] = 'E';
    this.pageControls['ESA.UserAdmin'] = 'E';
    this.pageControls['ESA.SafetyStickers'] = 'E';
    this.pageControls['ESA.SafetyReports'] = 'E';
    this.pageControls['ESA.FileUpload'] = 'E';
    this.setMenuItems();
  }

  changePassword(request: any, updateUser:string, callback, errorCallback) {
    this.postApi(environment.umaApiUrl + '/changePassword/' + updateUser, request, callback, errorCallback);
  }

  public dateFormatter(param) {
    return param.value != null? datePipe.transform(param.value):'N/A';
  }

  public arrayToMap(codeValues:[any]) {
    const result = {};
    if (codeValues !== null && codeValues.length > 0) {
      for (const codeValue of codeValues) {
        result[codeValue.code] = codeValue.values != null? codeValue.values:codeValue.value;
      }
    }
    return result;
  }

  getUserInfoByEmail(email: string, success: (result) => void, error: (err) => void) {
    this.getApi(this.umaApiUrl + '/getUserInfoByEmail?email='+email, success, error);
  }

  setSessionTimeout(){
    const sessionTimeout = new Date(this.getAuthTokenExpTime()*1000).getTime() - new Date().getTime();
    const idleTimeout = this.idleSessionTimeout*1000;

    if (sessionTimeout > 0 && sessionTimeout < idleTimeout) {
      this.refreshAccessToken();
    }
    else if (sessionTimeout < 0){
      if ( this.isPageEnabled('AMT.RealtimeAudits') ) {
        this.refreshAccessToken();
      }
      else {
        this.logout();
      }
    }
    else {
      if (this.idleStatus === 'NEW') {
        this.idle.watch();
        this.idleStatus = 'WATCH'
      }
      if (this.idleStatus === 'WATCH') {
        setTimeout(() => {
          this.refreshAccessToken();
        }, environment.refreshTokenTime*1000);
      }
    }
  }

  refreshAccessToken() {
    this.getApi( this.umaApiUrl + '/refreshAccessToken', this.refreshAccessTokenCallBack, this.refreshAccessTokenErrorCallBack);
  }

  refreshAccessTokenCallBack = (result:any) => {
    this.setAuthToken(result.token);
    if (result.pwdResetRequired == 'Y') {
      this.pwdResetRequired = true;
      this.router.navigate(['/changePassword']);
    } 
    else if (result.userStatusCd === 'E') {
      this.setSessionTimeout();
    }
    else {
      this.logout();
    }
  }

  refreshAccessTokenErrorCallBack = (error:any) => {
    alert($localize`Session has timed out. Please try logging in again!`);
    this.logout();
  }

  logout() {
     const collection = document.getElementsByClassName("sc-gzVnrw MAjwT");
     for (let i = 0; i < collection.length; i++) {
      let test: HTMLElement = collection[i] as HTMLElement;
      test.click();
      break;
    }
    this.callingKeycloakLogin = false;
    sessionStorage.removeItem("sessionActive");
    this.clearCookie('Authorization');
    this.clearCookie('pwdExpiryDays');
    this.clearCookie('secQAResetRequired');
    this.clearCookie('pwdChangeConfirmed');
    localStorage.clear();
    this.userId = null;
    this.menuItems = [];
    this.pwdResetRequired = false;
    this.pageControls = [];

    if (this.isSSOLogin()) {
      this.clearCookie('isKeycloakLogin');
      const uri = window.location.href.split('//');
      const logoutUrl = uri[0] + '//' + uri[1].substring(0, uri[1].indexOf('/'));
      this.keycloakService.logout(logoutUrl) ;
    } 
    else {
      this.openLoginScreen();
    }
  }

  cleanSession(redirectUrl:string) {
   this.callingKeycloakLogin = false;
   sessionStorage.removeItem("sessionActive");
   this.clearCookie('Authorization');
   this.clearCookie('pwdExpiryDays');
   this.clearCookie('secQAResetRequired');
   this.clearCookie('pwdChangeConfirmed');

   this.userId = null;
   this.menuItems = [];
   this.pwdResetRequired = false;
   this.pageControls = [];

   if (this.isSSOLogin()) {
     this.clearCookie('isKeycloakLogin');
     this.keycloakService.logout(redirectUrl) ;
   } 
 }

  openLoginScreen(){
    console.log("openLoginScreen="+window.location.href);
    if ( window.location.href.indexOf('on-login-web') > 0 ) {
//      if ( window.location.href.indexOf('index.html') > 0 ) {
        this.router.navigate(['/login/sso']);
//      }else {
//        this.router.navigate(['/login']);
//      }
    } else {
      window.location.href = '/on-login-web/' + getLocale() + '#/login';
    }
  }

  help(event) {
    event.preventDefault();
    const aLocale = getLocale();
    const app = window.location.pathname.split("-");
    const href = window.location.href.split("/");
    let aFunction = href[href.length-1];
    if (href.length > 6)
      aFunction = href[6];
    if (app[1]==="login" && aFunction==="")
      aFunction = "home";
    var aKey = app[1]+"/"+aFunction;
    if (app[1]==="login" && aFunction==="home" && this.isPageEnabled("LOGIN.TechnicianLanding"))
      aKey += "Technician";
    else if (app[1]==="login" && aFunction==="dashboard") {
      const dashboard = href[7].split("?");
      aKey += "/"+dashboard[0];
    }
    else if (app[1]==="login" && aFunction==="sheet") {
      const sheet = href[8].split("?");
      const sheetId = sheet[0].split("_");
      aKey += "/"+sheetId[sheetId.length-1];
    }
    const article = environment.articles[aKey];
    if (article != null) {
      var helpScreen = window.open(environment.zendeskUrl+"/hc/en-us/articles/"+article, "Onatrio VIC", "popup");
      helpScreen.focus();
    }
    else {
      alert("No help available for this page");
    }
    return false;
  }

  getStaticHTML(url: string, callback: (result) => void) {
    return fetch(url).then(response => response.text()).then(result => {
      callback(result);
    });
  }

  public errorCallback = (error:any) => {
    if (error.error != null && error.error.msg !== undefined && error.error.msg != null && error.error.msg.length > 0 ) {
      console.error(error.error.msg);
    }
    else {
      console.error(error.message + '(response code: ' + error.status + ')');
    }
  }

  addContactUs(contactUs:any, callback, errorCallback) {
    this.postApi( this.loginApiUrl + '/addContactUs', contactUs, callback, errorCallback);
  }

  showErrorMsg = (error:any) => {
    console.log('####### error: ' + JSON.stringify(error));
    if (error.status === 400) {
      alert($localize`Invalid request parameters!`);
    }
    else if (error.status === 503) {
      alert($localize`Service is not available. Please try again later!`);
    }
    else if (error.error != null && error.error.msg !== undefined &&
             error.error.msg != null && error.error.msg.length > 0) {
      console.error(error.error.msg);
      alert($localize`Oops, something went wrong! Please try again later`);
    }
    else {
      console.error(error.message + '(response code: ' + error.status + ')');
      alert($localize`Oops, something went wrong! Please try again later`);
    }
  }

  disableConsole() {
    if (environment.env !== 'DEV') {
      console.log = function (): void { };
      console.debug = function (): void { };
      console.warn = function (): void { };
      console.info = function (): void { };
    }
  }

  stopIdle() {
    this.idle.stop();
  }

  startIdle() {
    this.idle.watch();
  }

  goToHomePage() {
    window.location.href='/on-login-web/' + getLocale() + "/#/home";
  }

  postLoginCheck():boolean {
    const userStatus = this.getUserStatus();
    if (userStatus == 'E') {
      sessionStorage.setItem("sessionActive", "Y");
      const dialogConfig = new MatDialogConfig();
      dialogConfig.ariaLabel=" ";
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '560px';  
      const pwdExpiryDays = this.getCookie('pwdExpiryDays');
      const secQAResetRequired = this.getCookie('secQAResetRequired')

      if (pwdExpiryDays != null && pwdExpiryDays < 1 && this.getCookie('pwdChangeConfirmed') != 'Y') {
        if (pwdExpiryDays == -10000) {
          //change temp password
          this.pwdResetRequired = true;
          this.router.navigate(['/changePassword']);
        }else {
          //change expired password
          dialogConfig.data = {message: $localize`Sorry, your password is expired. Please click "OK" to change your password.`};
          const dialogRef = this.dialog.open(SimpleDialogComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(dialogData => {
            if (dialogData !== null && dialogData !== undefined) {
              this.pwdResetRequired = true;
              this.router.navigate(['/changePassword']);
            }
          });
        }
      } 
      else if (secQAResetRequired == 'Y') {
        //setSecurityQuestion
        dialogConfig.data = { type:'forget-password-Security-Question',title:$localize`Please enter your Security Question and Answer`, securityQuestionField:$localize`Security Question`, changeSecurityQuestion:true, securityQuestionAnswerField:$localize`Security Question Answer`, ok:$localize`Save`};
        const dialogRef = this.dialog.open(CustomDialogComponent, dialogConfig);
    
        dialogRef.afterClosed().subscribe(dialogData => {
          if (dialogData != null) {
            const userProfile = {
              userId: this.userId,
              securityQuestion: dialogData.securityQuestion,
              securityQuestionAnswer: dialogData.securityQuestionAnswer
            };
            this.postApi(environment.umaApiUrl + '/updateUserProfile/' + this.userId, userProfile, this.saveSecurityQuestionCallback, this.saveSecurityQuestionErrorCallback);
          }
        });
      } 
      else if (pwdExpiryDays != null && pwdExpiryDays < 15 && this.getCookie('pwdChangeConfirmed') != 'Y') {
        dialogConfig.data = { type:'confirm-dialog', title:$localize`Your password will expire in ` + pwdExpiryDays + $localize` days. Do you want to change your password now?`, ok:$localize`Yes`, cancel:$localize`No`};
        const dialogRef = this.dialog.open(CustomDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(dialogData => {
          this.setCookie('pwdChangeConfirmed', 'Y');
          if (dialogData !== null && dialogData !== undefined) {
            this.pwdResetRequired = false;
            this.router.navigate(['/changePassword']);
          } 
          else {
            return true;
          }
        });
      } 
      else {
        return true;
      }
    } 
    else {
      return false;
    }
  }

  saveSecurityQuestionCallback = (result: any) => {
    this.setCookie('secQAResetRequired', 'N');
    return true;
  }

  saveSecurityQuestionErrorCallback = (result: any) => {
    this.logout();
    alert($localize`Oops, something went wrong! Please try again later.`);
  }

  private vicInfo = new BehaviorSubject(0);
  vicInfo$ = this.vicInfo.asObservable();
  
  changeVicInfo(vicInfo: any) {
    this.vicInfo.next(vicInfo)
  }
  
}
