import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'apps/common/environments/environment';
import { CommonService } from 'apps/common/services/common.service';
import { LoginService } from '../../../services/login.service';
import { getLocale, isBlank, toggleRowSelected } from 'apps/common/helper/util-function-class';
import { MatPaginator } from '@angular/material/paginator';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { catchError, map } from 'rxjs';
import createReportCardPdf from '../../../template/pdfReportCardService';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake/build/vfs_fonts.js');
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-reports-screen',
  templateUrl: './reports-screen.component.html',
  styleUrls: ['./reports-screen.component.css']
})
export class ReportsScreenComponent implements OnInit {
  @ViewChild('pARReportTable', { read: MatSort, static: false }) pARReportTableSort: MatSort;
  @ViewChild('paginatorReport', { read: MatPaginator }) paginatorReport: MatPaginator;
  @ViewChild('fileUpload', { static: false }) fileUploadRef: ElementRef;
  version: string = environment.version;
  env: string = environment.env;
  bulletImage = 'assets/img/arrow_right.png';
  pARReportDataSource = new MatTableDataSource([]);
  defaultPARReportColumns = ['reportName','date','download','action' ];

  pARReports: any = [];
  stationReportCards: any = {};
  manualPARReports: any = {};
  isPRAParsonOrDelegate:boolean=false;
  PRA_PARSONS_ROLE_NAME = "praparsons";
  message: string = "";
  msgServiceError = $localize`Oops, something went wrong! Please try again later.`;
  lblUserId = $localize`User ID`;
  lblEmail = $localize`Email Address`;
  lblCancel = $localize`Cancel`;
  lblSubmit = $localize`Submit`;
  uploadMessage: string = null
  supportedUploadTypes = ["image/png", "image/jpeg",
    "application/pdf", "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
  loadingDocs = false;
  files = [];
  uploaDocs: any = [];
  selectedDoc = null;
  selectedStation: any = {};
  stationName:string;
  toggleRowSelected = toggleRowSelected;
  reportQuarters: any = [];
  searchCriteria: any = {};
  reportCardDataSource = new MatTableDataSource([]);
  reportCardColumns = ['stationId', 'stationName', 'address', 'inspectionCategoryCd', 'view', 'startDate', 'quarter', 'overallPerformanceGradeId'];
  docDefinition:any;
  action:string;
  expandReport:boolean = false;
  expandVICReport:boolean = false;
  currentUserRoles: any = [];
  searchApiURL = environment.icaaApiUrl + '/findStationOptions';
  isProd = environment.production;
  isIOS = false;
  currentLocale: string;
  
  constructor(
    public service: LoginService,
    public commonService: CommonService,
    private route: ActivatedRoute,    
    private spinnerService: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.currentLocale = getLocale();
    this.expandReport = false;
    this.expandVICReport = false;
    this.route.params.subscribe(params => {
      let stationId = params['stationId'];
      if (stationId != null && stationId.length > 0) {
        this.commonService.getStationById(stationId, this.getStationByIdCallback);
      }
    }); 

    if(this.isProd)
      this.searchApiURL = environment.icaaApiUrl + '/excludeParsonVicOptions';
      
    this.searchCriteria.inspectionCategoryCd = 'EMS';
    this.searchCriteria.locale = getLocale();
    this.service.getUserRoles(this.commonService.getCurrentUserid(), this.getUserRolesCallBack, this.commonService.showErrorMsg);
    let userAuthorities = this.commonService.getUserAuthorities();
    this.isPRAParsonOrDelegate= userAuthorities.filter(item => item.role.toLowerCase().includes(this.PRA_PARSONS_ROLE_NAME)).length > 0;
    this.service.getReportQuarters(this.getReportQuartersCallback, this.showErrorMsg);

    
    this.isIOS = (function () {
      var iosQuirkPresent = function () {
          var audio = new Audio();

          audio.volume = 0.5;
          return audio.volume === 1;   // volume cannot be changed from "1" on iOS 12 and below
      };

      var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      var isAppleDevice = navigator.userAgent.includes('Macintosh');
      var isTouchScreen = navigator.maxTouchPoints >= 1;   // true for iOS 13 (and hopefully beyond)

      return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
    })();
  }

  getUserRolesCallBack = (result: any) => {
    this.currentUserRoles = result;
    this.service.getParReportDetails(this.getParReportCallback, this.showErrorMsg);    
  }

  getParReportCallback = (result: any) => {
    result.forEach(item => {
      switch(item.key) {
        case 'VIS': {
          if(this.currentUserRoles.includes('VISReportAuthor') || this.currentUserRoles.includes('VISReportViewer'))
            this.pARReports.push(item);
          break;
        }
        case 'EPA': {
          if(this.currentUserRoles.includes('EPAReportAuthor') || this.currentUserRoles.includes('EPAReportViewer'))
            this.pARReports.push(item);
          break;
        }
        case 'UMA': {
          if(this.currentUserRoles.includes('UMAReportAuthor') || this.currentUserRoles.includes('UMAReportViewer'))
            this.pARReports.push(item);
          break;
        }
        case 'AMT': {
          if(this.currentUserRoles.includes('AMTReportAuthor') || this.currentUserRoles.includes('AMTReportViewer'))
            this.pARReports.push(item);
          break;
        }
        case 'TMQ': {
          if(this.currentUserRoles.includes('TMQReportAuthor') || this.currentUserRoles.includes('TMQReportViewer'))
            this.pARReports.push(item);
          break;
        }
        case 'FCRA': {
          if(this.currentUserRoles.includes('FCRAReportAuthor') || this.currentUserRoles.includes('FCRAReportViewer'))
            this.pARReports.push(item);
          break;
        }
        case 'PRA': {
          if(this.currentUserRoles.includes('COMMONReportAuthor') || this.currentUserRoles.includes('COMMONReportViewer'))
            this.pARReports.push(item);
          break;
        }
        default: {
        }
      }
    })
  }

  getStationByIdCallback = (result: any) => {
    if (result !== null) {
      this.selectedStation = result;
      this.expandVICReport = true;
      this.searchCriteria.stationId=this.selectedStation.stationId;
      this.stationName = this.selectedStation.stationName+" ("+this.selectedStation.stationId+")";
      this.search();
    }
  }

  getReportQuartersCallback = (result: any) => {
    this.reportQuarters = result;
    result.forEach((obje, index) => {
      if(index == 0) {
        this.searchCriteria.reportPeriod = obje.code;
        this.searchCriteria.reportPeriodValue = obje.value;
        this.route.params.subscribe(params => {
          let stationId = params['stationId'];
          this.onChangeStationId(stationId);
          if (stationId != null && stationId.length > 0) {
            this.expandReport = true;
            this.search();
          }
        });
        return;
      }
    });
  }

  onChangeStationId(stationId: any) {
    if (isNaN(stationId)) {
      this.searchCriteria.stationId = undefined;
    }
    else {
      this.searchCriteria.stationId = stationId;

    }
  }

  onStationSelected(value: any) {
    this.searchCriteria.stationId = value;
  }

  search() {
    this.message = "";
    if(this.searchCriteria.stationId != undefined && this.searchCriteria.reportPeriod != undefined) {
      this.spinnerService.show();
      const dateArray = this.searchCriteria.reportPeriod.split(",");
      this.searchCriteria.startDate = dateArray[0];
      this.searchCriteria.endDate = dateArray[1];
      this.service.getStationReportCards(this.searchCriteria, this.getStationReportCardsCallback, this.showErrorMsg);
    }
    else {
      this.message = $localize`Please enter VIC ID`;
    }
  }

  getStationReportCardsCallback = (result: any) => {
    this.spinnerService.hide();
    if (result != null && result.length > 0) {
      this.stationReportCards = result;
      this.reportCardDataSource = new MatTableDataSource(this.stationReportCards);
    }
    else {
      this.message = $localize`No data found`;
      this.reportCardDataSource = new MatTableDataSource([]);
    }
  }

  view(row) {
    this.action = 'view';
    this.service.getStationReportCard(row.inspectionCategoryCd, row.stationId, row.startDate, row.endDate, this.getStationReportCardCallback, this.showErrorMsg);
    return false;
  }

  print(row) {
    this.action = 'print';
    this.service.getStationReportCard(row.inspectionCategoryCd, row.stationId, row.startDate, row.endDate, this.getStationReportCardCallback, this.showErrorMsg);
  }

  download(row) {
    this.action = 'download';
    this.service.getStationReportCard(row.inspectionCategoryCd, row.stationId, row.startDate, row.endDate, this.getStationReportCardCallback, this.showErrorMsg);
    return false;
  }

  getStationReportCardCallback= (result: any) => {
    if(result != null && result.stationList != null && result.stationList.length > 0) {
      for(const aStationDto of result.stationList) {
        aStationDto.aLocale = this.searchCriteria.locale;
        aStationDto.inspectionCategoryCd = result.inspectionCategoryCd;
        this.docDefinition = createReportCardPdf(aStationDto, result.pdList, result.pgList, result.tvList);
        switch(this.action) {
          case 'view': {
            pdfMake.createPdf(this.docDefinition).open();
            break;
          }
          case 'print': {
            pdfMake.createPdf(this.docDefinition).print();
            break;
          }
          case 'download': {
            pdfMake.createPdf(this.docDefinition).download(aStationDto.reportFileName);
            break;
          }
          default: {
          }
        }
        break;
      }
    }
    else if (result != null) {
      console.error('getStationReport error:\n ' + JSON.stringify(result));
    } 
    else {
      console.error('getStationReport failed.');
    }
  }

  showErrorMsg = (error: any) => {
    this.spinnerService.hide();
    if (error.error.message != null && error.error.message.length > 0) {
      this.message = error.error.message;
    } 
    else {
      this.message = error.message + '(response code: ' + error.status + ')';
    }
  }

  populateManualReports(item:any){
    this.manualPARReports = item.manualReports;
    this.pARReportDataSource = new MatTableDataSource(this.manualPARReports);
    this.pARReportDataSource.sort = this.pARReportTableSort;
  }

  onUploadFiles(moduleId: any,reportId: any) {
    this.uploadMessage = "";
    let unsupportedfileNames = "";
    this.files = [];
    const fileUpload = this.fileUploadRef.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        if (this.supportedUploadTypes.includes(file.type))
          this.files.push(file);
        else
          unsupportedfileNames = unsupportedfileNames + file.name + " ";
      }
      if (unsupportedfileNames)
        this.uploadMessage = $localize`The files ` + unsupportedfileNames + $localize`are not supported file formats.`
      this.uploadDoc(this.files, moduleId,reportId);
    };
    fileUpload.click();
  }

  uploadDoc(files, moduleId,reportId) {
    this.loadingDocs = true;
    const formData = new FormData();
    for (var i = 0; i < this.files.length; i++) {
      formData.append("files", this.files[i]);
    }
    this.service.uploadAuditDoc(moduleId,reportId, formData).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            break;
          case HttpEventType.Response:
            alert($localize`Succesfully uploaded the report`);
            this.ngOnInit();
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        alert($localize`Upload failed`);
        return 'upload failed.';
      })).subscribe((event: any) => {
        if (typeof (event) === 'object') {
          console.log(event.body);
        }
      });
    this.fileUploadRef.nativeElement.value = null;
  }

  downloadReport(reportId,fileName) {
    this.service.downloadReport(reportId).subscribe(
      response => {
        this.spinnerService.hide();
        let blob: any = new Blob([response.body], { type: response.body.type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
      }, err => {
        this.message = 'Could not download the file!' + err;
      });
  }

  showQuickSightReport(report) {
    const aUrl = this.currentLocale === 'en'?report.reportUrl:report.frenchReportUrl;
    if(report.reportType == 'Q') {      
      let urls = aUrl.split("/");
      let last = urls[urls.length-1].split("?");
      window.open(window.location.protocol+"//"+window.location.host+"/on-login-web/"+getLocale()+"/#/dashboard/"+last[0]);
    }else{
      window.open(aUrl);
    }

  }
}
