import { HttpEvent } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { integer } from 'aws-sdk/clients/cloudfront';
import { Observable } from 'rxjs';
import {environment} from '../../../../common/environments/environment';
import {CommonService} from '../../../../common/services/common.service';
import { getLocale} from 'apps/common/helper/util-function-class';

@Injectable()
export class LoginService {

  private amtApiUrl = environment.amtApiUrl;
  private icaaApiUrl = environment.icaaApiUrl;
  private icpaaApiUrl = environment.icpaaApiUrl;
  private loginApiUrl = environment.loginApiUrl;
  private umaApiUrl = environment.umaApiUrl;
  private lookupApiUrl = environment.lookupApiUrl;
  private reportCardApiUrl = environment.reportCardApiUrl;
  private lookupAdminApiUrl = environment.lookupAdminApiUrl;

  public captcha = {
    'srcString': '' ,
    'captchaValue': ''
  } ;

  IC_USER = 3;
  IC_OWWER = 15;
  IC_OWWER_TECH = 2;
  IC_TECH = 1;

  public org: any = [] ;
  // public regions: any = [];
  public depart: any = [];

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.getVerificationCode() ;
  }

  login(request:any, callback, errorCallback) {
    this.commonService.postApi( this.loginApiUrl + '/login', request, callback, errorCallback);
  }

  getSecQuestion(searchId:string, callback, errorCallback) {
    this.commonService.getApi(this.loginApiUrl + '/getSecurityQuestion?searchId=' + searchId, callback, errorCallback);
  }

  resetPassword(request:any, callback, errorCallback) {
    this.commonService.postApi(this.loginApiUrl + '/resetPassword', request, callback, errorCallback);
  }

  registerUser(request: any, callback, errorCallback) {
    this.commonService.postApi(this.loginApiUrl + '/registerUser', request, callback, errorCallback);
  }

  genUserId(name: string, callback, errorCallback) {
    this.commonService.getApi(this.loginApiUrl + '/genUserId?name=' + name, callback, errorCallback);
  }

  genNumberUserId(callback, errorCallback) {
    this.commonService.getApi(this.loginApiUrl + '/genUserId', callback, errorCallback);
  }

  getVerificationCode() {
    this.commonService.getApi(this.loginApiUrl + '/getCaptcha?timestamp=' + new Date().toISOString(), this.setCaptcha, this.showError);
  }

  getUserProfile(userid:string, callback, errorCallback) {
    this.commonService.getApi(this.umaApiUrl + '/getUserProfile/' + userid, callback, errorCallback);
  }

  updateUserProfile(userid: any, userProfile: any, callback, errorCallback) {
    this.commonService.postApi(this.umaApiUrl + '/updateUserProfile/' + userid, userProfile, callback, errorCallback);
  }

  getStationStatusInfo(stationId:string, callback, errorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/getStationStatusInfo/' + stationId, callback, errorCallback);
  }

  changePassword(request:any, updateUser:string, callback, errorCallback) {
    this.commonService.postApi(this.umaApiUrl + '/changePassword/' + updateUser, request, callback, errorCallback);
  }

  getStationInfo(stationId:string, callBack, errorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/getStationInfo/' + stationId, callBack, errorCallback);
  }


  getStationBillingContacts(stationId:string, callBack, errorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/getStationBillingContacts/' + stationId, callBack, errorCallback);
  }
  signEnrollment(updateUser:string, enrollment:any, callback, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/signEnrollment/' + updateUser, enrollment, callback, errorCallback);
  }

  signAchForm(updateUser:string, achForm:any, callback, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/signAchForm/' + updateUser, achForm, callback, errorCallback);
  }

  getParReportDetails(getParReportCallback: (result: any) => void, showErrorMsg: (error: any) => void) {
    this.commonService.getApi(this.umaApiUrl + '/report/getReportsDetails', getParReportCallback, showErrorMsg);
  }

  getManualParReportDetails(getParReportCallback: (result: any) => void, showErrorMsg: (error: any) => void) {
    this.commonService.getApi(this.umaApiUrl + '/report/getManualReportsDetails', getParReportCallback, showErrorMsg);
  }

  getOrganizations(callback:any) {
    this.commonService.lookup('Employer', callback);
  }

  getJobFunctions( empid: string) {
    this.commonService.lookup('Department/' + empid, this.setDepts);
  }

  uploadAuditDoc(dcisModuleCd:string,reportId:integer, data:any): Observable<HttpEvent<any>> {
    return this.commonService.uploadFile(this.umaApiUrl + '/uploadReport/' + dcisModuleCd +"/"+reportId , data);
  }

  downloadReport(reportId:integer): Observable<any>  {
    return this.commonService.downloadFile(this.umaApiUrl + '/downloadReport/' + reportId);
  }

  getStationReportCards(searchCriteria:any, callBack, errorCallback) {
    this.commonService.getApi(this.reportCardApiUrl + '/getReportCardsByStation/'+searchCriteria.stationId+"/"+searchCriteria.startDate+"/"+searchCriteria.endDate, callBack, errorCallback);
  }

  getStationReportCard(inspectionCategoryCd:string, stationId:string, startDate:string, endDate:string, callBack, errorCallback) {
    this.commonService.getApi(this.reportCardApiUrl + '/getReportCardByStation/'+inspectionCategoryCd+'/'+stationId+'/'+startDate+'/'+endDate, callBack, errorCallback);
  }

  getReportCardByCategory(inspectionCategoryCd:string, stationId:string, callBack, errorCallback) {
    this.commonService.getApi(this.reportCardApiUrl + '/getReportCardByCategory/'+inspectionCategoryCd+'/'+stationId, callBack, errorCallback);
  }

  getReportQuarters(callBack, errorCallback) {
    this.commonService.getApi(this.reportCardApiUrl + '/getReportQuarters', callBack, errorCallback);  
  }

  getUserRoles(userid:string, callback, errorCallback) {
    this.commonService.getApi(this.umaApiUrl + '/getUserRoles/' + userid, callback, errorCallback);
  }

  setDepts = (o:any) => {
    this.depart = [] ;
    const result: Array<any> = o.lookup.Department;
    for (let item of result) {
      this.depart.push([item .code,
        item .value]) ;
    }
  }

  // getRegions( ) {
  //   this.commonService.lookup('AuditRegion', this.setRegions);
  // }

  // setRegions = (o:any) => {
  //   this.regions  = [] ;
  //   const result: Array<any> = o.lookup.AuditRegion ;
  //   for (let item of result) {
  //     this.regions.push([item .code,
  //       item .value]) ;
  //   }
  // }

  setCaptcha = (res) => {
    this.captcha = res ;
  };

  showError = (err) => {
    console.log(err);
  };

  getOwnerOnboardingStatus(personId: string, userId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getOwnerOnboardingStatus/" + personId + "/" + userId, callBack, errorCallBack);
  }

  getStationIdsByOwner(personId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getStationIdsByOwner/" + personId, callBack, errorCallBack);
  }

  getTechnicianIdsByOwner(personId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getTechnicianIdsByOwner/" + personId, callBack, errorCallBack);
  }

  getStationLockouts(stationIds: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getStationLockoutsByStationIds/" + stationIds, callBack, errorCallBack);
  }

  getTechnicianLockouts(technicianIds: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icpaaApiUrl  + "/getTechnicianLockoutsByTechnicianIds/" + technicianIds, callBack, errorCallBack);
  }

  getStationSuspensions(stationIds: string, callBack, errorCallBack) {
    this.commonService.getApi(this.amtApiUrl  + "/getStationSuspensions/" + stationIds, callBack, errorCallBack);
  }

  getTechnicianRetrainings(technicianIds:string, callBack, errorCallBack) {
    this.commonService.getApi(this.amtApiUrl  + "/getTechnicianRetrainings/" + technicianIds, callBack, errorCallBack);
  }

  updateStationStatusCode(stationId:string, stationStatusCd:string, userId:string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/updateStationStatusCode/" + stationId + "/" + stationStatusCd + "/" + userId, callBack, errorCallBack);
  }

  acknowledgeAudit(auditId:string, userId:string, callBack, errorCallBack) {
    this.commonService.postApi(this.amtApiUrl + "/acknowledgeAudit/" + auditId + "/" + userId, null, callBack, errorCallBack);
  }

  getDashboardUrl(id, callback, errorCallback) {
    // this.commonService.getApi(this.loginApiUrl + '/api/getQuicksightEmbeddedDashboardUrl/'+id+'/'+this.commonService.isQuickSightAuthor()+'/'+this.commonService.getCurrentUserid(), callback, errorCallback);
    this.commonService.getApi(this.loginApiUrl + '/api/getDashboardUrl/'+id+'/'+this.commonService.getCurrentUserid(), callback, errorCallback);
  }

  getConsoleUrl(id, callback, errorCallback) {
    this.commonService.getApi(this.loginApiUrl + '/api/getQuicksightEmbeddedConsoleUrl/'+id+'/'+this.commonService.getCurrentUserid(), callback, errorCallback);
  }

  getVicOwnerReContractList(personId: string, userId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getVicOwnerReContractList/" + personId + "/" + userId, callBack, errorCallBack);
  }

  getPersonCertification(personId: any, callBack, errorCallBack) {
    this.commonService.getApi(this.icpaaApiUrl  + "/getPersonCertificationFull/" + personId, callBack, errorCallBack);
  }

  getMembershipInfo(personId: any, callBack, errorCallBack) {
    this.commonService.getApi(this.icpaaApiUrl  + "/getMembershipInfo/" + personId, callBack, errorCallBack);
  }

  getStoTradeCodes(callBack, errorCallBack) {
    this.commonService.getApi(this.lookupApiUrl  + "/getStoTradeCodes", callBack, errorCallBack);
  }

  getPortalTrainingVideos(callBack, errorCallBack) {
    this.commonService.getApi(this.umaApiUrl  + "/getPortalTrainingVideos", callBack, errorCallBack);
  }

  getPortalBulletins(callBack, errorCallBack) {
    this.commonService.getApi(this.lookupApiUrl  + "/getPortalBulletins", callBack, errorCallBack);
  }  

  public mtoApprove(userId:string, callback, errorCallBack) {
    this.commonService.postApi(this.icaaApiUrl + '/mtoApproveFromDashboard/'+userId, null, callback, errorCallBack);
  }

  public directorApprove(userId:string, callback, errorCallBack) {
    this.commonService.postApi(this.icaaApiUrl + '/directorApproveFromDashboard/'+userId, null, callback, errorCallBack);
  }

  public mtoSingleApprove(userId:string, stationId:string, callback, errorCallBack) {
    this.commonService.postApi(this.icaaApiUrl + "/mtoSingleApproveFromDashboard/"+userId+"/"+stationId, null, callback, errorCallBack);
  }

  public directorSingleApprove(userId:string, stationId:string, callback, errorCallBack) {
    this.commonService.postApi(this.icaaApiUrl + '/directorSingleApproveFromDashboard/'+userId+"/"+stationId, null, callback, errorCallBack);
  }

  getPortalSopAndDirectives(callBack, errorCallBack) {
    this.commonService.getApi(this.lookupApiUrl  + "/getPortalSopAndDirectives", callBack, errorCallBack);
  }

  getDashboardId(callBack, errorCallBack) {
    this.commonService.getApi(this.umaApiUrl  + "/getDashboardId", callBack, errorCallBack);
  }
  
  getScreenNotice(noticeId, callBack) {
    this.commonService.getApi(this.lookupApiUrl  + '/getScreenNotice/' + getLocale() + '/' + noticeId, callBack, this.commonService.errorCallback);
  }

  public isOnboardingValid(callback) {
    this.commonService.getApi(this.lookupAdminApiUrl + '/isOnboardingValid', callback, this.commonService.errorCallback);
  }

  getInspectionCentreList(personId: any, userId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getInspectionCentreList/" + personId + "/" + userId, callBack, errorCallBack);
  }
}

