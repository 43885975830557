import {Component, OnInit} from '@angular/core';
import {CommonService} from 'apps/common/services/common.service';
import { environment } from 'apps/common/environments/environment';

declare var startChat: Function;

@Component({
  selector: 'aws-chat-field',
  templateUrl: './aws-chat.component.html',
  styleUrls: ['./aws-chat.component.css']
})

export class AwsChatComponent implements OnInit {
  firstName:string = "";
  userId:string = "";
  personId:number = null;
  customerNumber:string = "";
  isLoggedIn:boolean = false;
  onlineModuleUrl = environment.onlineModuleUrl;
  aUrl:string = "";

  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.firstName = this.commonService.getUserName();
    this.userId = this.commonService.getUserId();
    this.personId = this.commonService.getPersonId();
    this.isLoggedIn = this.commonService.isLoggedIn()?true:false;
    this.commonService.getOwnerCompany(this.personId, this.getOwnerCompanyCallback, this.commonService.errorCallback);
  }

  getOwnerCompanyCallback = (result: any) => {
    if(result) {
      if(result.businessNum != null && result.businessNum.toString().length === 10)
      {
        this.customerNumber =  result.businessNum.toString().substring(0, 3) + "-" + result.businessNum.toString().substring(3, 6) + "-" + result.businessNum.toString().substring(6);
      }else if(result.phoneNum != null && result.phoneNum.toString().length === 10) {
        this.customerNumber =  result.phoneNum.toString().substring(0, 3) + "-" + result.phoneNum.toString().substring(3, 6) + "-" + result.phoneNum.toString().substring(6);
      }else{
        this.commonService.getUserProfile(this.userId, this.getUserProfileCallback, this.commonService.errorCallback)
      }
    }
  }

  getUserProfileCallback = (result: any) => {
    if(result) {
      if(result.busPhoneNum != null && result.busPhoneNum.length === 10)
        {
          this.customerNumber =  result.busPhoneNum.substring(0, 3) + "-" + result.busPhoneNum.substring(3, 6) + "-" + result.busPhoneNum.substring(6);
        }else if(result.mobilePhoneNum != null && result.mobilePhoneNum.length === 10) {
          this.customerNumber =  result.mobilePhoneNum.substring(0, 3) + "-" + result.mobilePhoneNum.substring(3, 6) + "-" + result.mobilePhoneNum.substring(6);
        }else{
          this.customerNumber = "000-000-0000";
        }
    }
  }

  chat() {
    let customerName = this.firstName;
    let userName = this.userId + "-" + new Date().toISOString();
    // this.aUrl = this.onlineModuleUrl + "chat" + "/chat.html?customerName=" + customerName +"&userName=" + this.userName;
    // window.open(this.aUrl, "width=365, height=525,left=0,top=0,toolbar=0,status=0,");
    startChat(customerName, userName, this.customerNumber);
  }
}
