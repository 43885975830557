import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuardService} from '../../../../../common/services/auth-guard.service';
import {ChangePasswordComponent} from '../../../../../common/components/change-password/change-password.component';

import {UserProfileGuardService} from '../../services/user-profile-guard.service';

import {MainScreenComponent} from '../screens/main-screen/main-screen.component';
import {LoginScreenComponent} from '../screens/login-screen/login-screen.component';
import {UserRegistrationStep1Component} from '../screens/user-registration/step1/step1.component';
import {UserRegistrationStep2Component} from '../screens/user-registration/step2/step2.component';
import {UserRegistrationStep3Component} from '../screens/user-registration/step3/step3.component';
import {UserRegistrationStep4Component} from '../screens/user-registration/step4/step4.component';
import {StationUserRegistrationStep1Component} from '../screens/station-user-registration/step1/step1.component';
import {StationUserRegistrationStep2Component} from '../screens/station-user-registration/step2/step2.component';
import {StationUserRegistrationStep3Component} from '../screens/station-user-registration/step3/step3.component';
import {StationUserRegistrationStep4Component} from '../screens/station-user-registration/step4/step4.component';
import {ReportsScreenComponent} from '../screens/reports/reports-screen.component';
import {QuickSightComponent} from '../screens/quick-sight/quick-sight.component';
import {DashboardComponent} from '../screens/dashboard/dashboard.component';
import {SheetComponent} from '../screens/sheet/sheet.component';
import {TrainingVideosComponent} from '../screens/training-videos/training-videos.component';
import {SopAndDirectvieComponent} from '../screens/sop-and-directvie/sop-and-directvie.component';
import {BulletinDocumentsComponent} from '../screens/bulletin-documents/bulletin-documents.component'
import { EnrolmentComponent } from '../screens/enrolment/enrolment.component';
import { ReportCardComponent } from '../screens/report-card/report-card.component';
import { OnboardingStepsComponent } from "../../../../../icaa/src/app/component/screens/onboarding-steps/onboarding-steps.component";

const routes: Routes = [
  { path: 'ssologin', redirectTo: 'login/sso', pathMatch: 'full'},
  { path: 'login', component: LoginScreenComponent},
  { path: 'login/:sso', component: LoginScreenComponent},
  { path: 'enrolment', component: EnrolmentComponent},
  { path: 'home', component: MainScreenComponent, canActivate: [AuthGuardService]},
  { path: 'home/:from', component: MainScreenComponent, canActivate: [AuthGuardService]},
  { path: 'user-register-step1', component: UserRegistrationStep1Component},
  { path: 'user-register-step2', component: UserRegistrationStep2Component, canActivate: [UserProfileGuardService]},
  { path: 'user-register-step3', component: UserRegistrationStep3Component, canActivate: [UserProfileGuardService]},
  { path: 'user-register-step4', component: UserRegistrationStep4Component, canActivate: [UserProfileGuardService]},
  { path: 'station-user-register-step1', component: StationUserRegistrationStep1Component},
  { path: 'station-user-register-step2', component: StationUserRegistrationStep2Component, canActivate: [UserProfileGuardService]},
  { path: 'station-user-register-step3', component: StationUserRegistrationStep3Component, canActivate: [UserProfileGuardService]},
  { path: 'station-user-register-step4', component: StationUserRegistrationStep4Component, canActivate: [UserProfileGuardService]},
  { path: 'changePassword', component: ChangePasswordComponent},
  { path: 'reports', component: ReportsScreenComponent, canActivate: [UserProfileGuardService]},
  { path: 'reports/report-card/:personId', component: ReportCardComponent, canActivate: [UserProfileGuardService]},
  { path: 'reports/:stationId', component: ReportsScreenComponent, canActivate: [UserProfileGuardService]},
  { path: 'quick-sight/:id', component: QuickSightComponent, canActivate: [UserProfileGuardService]},
  { path: 'dashboard/:id', component: DashboardComponent, canActivate: [UserProfileGuardService]},
  { path: 'sheet/:dashboardId/:sheetId', component: SheetComponent, canActivate: [UserProfileGuardService]},
  { path: 'trainingVideos', component: TrainingVideosComponent, canActivate: [AuthGuardService]},
  { path: 'sopAndDirectives', component: SopAndDirectvieComponent, canActivate: [AuthGuardService]},
  { path: 'bulletinDocuments', component: BulletinDocumentsComponent, canActivate: [AuthGuardService]},
  { path: 'report-card/:stationId', component: ReportCardComponent, canActivate: [AuthGuardService]},
  { path: 'inspectionCentreOnboardingSteps', component: OnboardingStepsComponent, canActivate: [AuthGuardService]},
  { path: 'inspectionCentreOnboardingSteps/:stationId', component: OnboardingStepsComponent, canActivate: [AuthGuardService]},
  { path: '**', redirectTo: 'login/sso', pathMatch: 'full'}
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes , { 
      useHash: true, relativeLinkResolution: 'legacy', anchorScrolling: 'enabled' 
    }) 
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
